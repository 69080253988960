/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-await */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable no-use-before-define */
/* eslint-disable object-curly-newline */
/* eslint-disable object-shorthand */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-const-assign */
// DEPENDENCIES
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
import {
  DragDropContext,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';
// ICONS
import { TbCheck, TbX } from 'react-icons/tb';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import ModalBlock from '../../../../components/ModalBlock';
import InputBlock from '../../../../components/InputBlock';
import TextAreaBlock from '../../../../components/TextAreaBlock';
import SelectBlock from '../../../../components/SelectBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import Image from '../../../../components/Image';
import Overlay from '../../../../components/Overlay';
import Button from '../../../../components/Button';
import FileUploadBlock from '../../../../components/FileUploadBlock';
import FixedActionsBar from '../../../../components/FixedActionsBar';
import SelectablePreview from '../../../../components/SelectablePreview';
// ASSETS
import DynamicBannerSectionImage from '../../../../assets/img/banner-section.svg';
// SERVICES AND HELPERS
import constants from '../../../../constants/constants';
import * as helper from '../../../../helpers/helper';
import BannerValidator from '../../../../helpers/validators/cms/banner/BannerValidator';
import BannerItemValidator from '../../../../helpers/validators/cms/banner/BannerItemValidator';
import * as bannerService from '../../../../services/cms/bannerService';
import * as bannerItemService from '../../../../services/cms/bannerItemService';
// REDUX
import * as auth from '../../../../redux/authRedux';
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModalRedux from '../../../../redux/confirmModalRedux';

const initialBannerModel = {
  id: 0,
  pageId: 0,
  bannerId: 0,
  name: '',
  isActive: true,
  bannerItems: []
};

const linkTypes = [
  { value: constants.CONTENT_SECTION.ButtonActionTypePage, label: 'Page' },
  { value: constants.CONTENT_SECTION.ButtonActionTypeLink, label: 'Link (New Tab)' },
  { value: constants.CONTENT_SECTION.ButtonActionTypePhoneNumber, label: 'Phone Number' },
  { value: constants.CONTENT_SECTION.ButtonActionTypeEmail, label: 'Email Address' },
];

const alignmentOptions = [
  { value: constants.CONTENT_SECTION.LeftAlignment, label: 'Left (Default)' },
  { value: constants.CONTENT_SECTION.CenterAlignment, label: 'Center' },
  { value: constants.CONTENT_SECTION.RightAlignment, label: 'Right' },
];

const components = [
  {
    id: 1,
    title: 'Banner',
    image: DynamicBannerSectionImage,
    type: constants.CONTENT_SECTION.BannerDynamicBanner
  },
];

const BannerManagementPage = (props) => {
  const { showAlert, history, showConfirmModal, hideConfirmModal } = props;
  const { bannerId, pageId, blogId } = useParams();
  const filePondRef = useRef(null);
  const initialBannerItemModel = {
    id: 0,
    bannerId: bannerId,
    isActive: true,
    type: constants.CONTENT_SECTION.ContentSectionDynamicBanner,
    title: '',
    name: '',
    description: '',
    alignment: 'LEFT',
    imagePath: '',
    image: null,
    isPadded: false,
    isFloatingCard: false,
    hasRoundedCorners: false,
    isTextOnly: false,
    primaryButtonText: '',
    primaryButtonLink: '',
    primaryButtonActionType: null,
    secondaryButtonText: '',
    secondaryButtonLink: '',
    secondaryButtonActionType: null,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [bannerModel, setBannerModel] = useState({
    ...initialBannerModel,
    pageId: pageId || 0,
    blogId: blogId || 0
  });
  const [bannerItemModel, setBannerItemModel] = useState(initialBannerItemModel);
  const [alignment, setAlignment] = useState([{ label: 'Left (Default)', value: 'LEFT' }]);
  const [primaryActionType, setPrimaryActionType] = useState(null);
  const [secondaryActionType, setSecondaryActionType] = useState(null);
  const [file, setFile] = useState([]);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [sortList, setSortList] = useState([]);

  useEffect(() => {
    if (bannerId > 0) {
      getBanner();
    }
  }, []);

  useEffect(() => {
    setSortList(bannerModel.bannerItems.sort((a, b) => a - b));
  }, [bannerModel.bannerItems]);

  const formik = useFormik({
    initialValues: bannerModel,
    validationSchema: BannerValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (bannerId) {
        updateBanner(values);
      } else {
        createBanner(values);
      }
    },
  });

  const formik_bannerItem = useFormik({
    initialValues: bannerItemModel,
    validationSchema: BannerItemValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (values.id > 0) {
        updateBannerItem(values);
      } else {
        createBannerItem(values);
      }
    },
  });

  const getBanner = async () => {
    setIsLoading(true);

    bannerService.getBannerById(bannerId).then((res) => {
      setBannerModel(res);
      fillSelectedOptions(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const fillSelectedOptions = (res) => {
    let selectedAlignment = alignmentOptions.find((x) => x.value === res.alignment);
    let selectedPrimaryActionType = linkTypes.find((x) => x.value === res.primaryButtonActionType);
    let selectedSecondaryActionType = linkTypes.find((x) => x.value === res.secondaryButtonActionType);

    if (selectedAlignment) {
      setAlignment(selectedAlignment);
    }

    if (selectedPrimaryActionType) {
      setPrimaryActionType(selectedPrimaryActionType);
    }

    if (selectedSecondaryActionType) {
      setSecondaryActionType(selectedSecondaryActionType);
    }
  };

  const createBanner = (model) => {
    setIsLoading(true);

    bannerService.createBanner(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push(`/cms/management/banner/edit/${res.id}`);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const updateBanner = (model) => {
    setIsLoading(true);

    bannerService.updateBanner(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/cms/management/content-sections');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const createBannerItem = (model) => {
    if (file.length === 0) {
      showAlert({ text: 'Error! Please upload an image', state: 'warning' });
      return;
    }

    setIsLoading(true);
    bannerItemService.createBannerItem(helper.convertJsonToFormData(
      {
        ...model,
        image: file.length > 0 ? file[0].file : null
      }
    )).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      resetBannerItemForm();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const updateBannerItem = (model) => {
    setIsLoading(true);

    if (file.length > 0) {
      model = { ...model, image: file[0].file };
    }
    bannerItemService.updateBannerItem(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      resetBannerItemForm();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const resetBannerItemForm = () => {
    getBanner();
    resetBannerItemEditFormFields();
  };

  const resetBannerItemEditFormFields = () => {
    setBannerItemModel(initialBannerItemModel);
    formik_bannerItem.resetForm();
    filePondRef.current.removeFiles();
    setPrimaryActionType(null);
    setSecondaryActionType(null);
  };

  const removeBannerItem = (id) => {
    bannerItemService.deleteBannerItem(id).then((res) => {
      hideConfirmModal();
      showAlert({ text: res.message, state: 'success' });
      resetBannerItemForm();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const sortBannerItems = () => {
    setIsLoading(true);

    bannerItemService.sortBannerItems(sortList).then((res) => {
      getBanner();
      showAlert({ text: res.message, state: 'success' });
      setIsSortModalOpen(false);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const tempArr = helper.reOrderDnd(
      sortList,
      result.source.index,
      result.destination.index
    );

    setSortList(tempArr);
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="Edit"
              className="info--bg ml-0"
              size="xxs"
              onClick={() => {
                resetBannerItemEditFormFields();
                setTimeout(() => {
                  setBannerItemModel(bannerModel.bannerItems[dataIndex]);
                  fillSelectedOptions(bannerModel.bannerItems[dataIndex]);
                }, 0);
              }}
            />

            <Button
              text="Remove"
              className="danger--bg"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Remove Item',
                  text: 'Are you sure you want to remove this banner item?',
                  rightBtnText: 'Confirm',
                  btnAction: () => {
                    removeBannerItem(bannerModel.bannerItems[dataIndex].id);
                  }
                });
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        print: false,
        download: true
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        filter: true,
        sort: true,
        print: false,
        download: true
      },
    },
    {
      name: 'isActive',
      label: 'Is Active?',
      options: {
        filter: true,
        sort: true,
        print: false,
        download: true,
        // customBodyRenderLite: (dataIndex) => {
        //   const { isActive } = bannerModel.bannerItems[dataIndex];

        //   return (
        //     <p>{isActive ? 'Yes' : 'No'}</p>
        //   );
        // }
        customBodyRenderLite: (dataIndex) => {
          const { isActive } = bannerModel.bannerItems[dataIndex];
          return (
            isActive ? <TbCheck size={26} strokeWidth={2} className="success--clr" /> : <TbX size={26} strokeWidth={2} className="danger--clr" />
          );
        }
      },
    }
  ];

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock cols={4}>
            <CustomBlock
              hasNoContainer
              className="content-container--card-style--with-shadow"
            >
              <ContentHeader
                title="Component"
                headerSize="lg"
              />

              <CustomBlock className="selectable-preview-container">
                {
                  components.map((item) => (
                    <SelectablePreview
                      key={item.id}
                      title={item.title}
                      imageSource={item.image}
                      isSelected={item.type === formik.values.type}
                      titleColor={item.type === formik.values.type ? 'success--clr' : 'mid-grey--clr'}
                    />
                  ))
                }
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>

          <ContentBlock cols={8}>
            <FormBlock onSubmit={formik.handleSubmit}>
              {/* BASIC INFORMATION */}
              <Section
                hasNoContainer
                className="content-container--card-style--with-shadow"
              >
                <ContentBlock>
                  <ContentHeader
                    title={formik.values.id === 0 ? 'New Banner' : 'Banner Details'}
                    headerSize="lg"
                  />
                </ContentBlock>

                <ContentBlock>
                  <ContentHeader
                    title="Basic Information"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={6}>
                  <InputBlock
                    label="Banner Name"
                    placeholder="e.g. Call-To-Action Section"
                    isRequired
                    errorMessage={formik.errors.name}
                    inputState={`${helper.getInputClasses(formik, 'name')}`}
                    {...formik.getFieldProps('name')}
                  />
                </ContentBlock>
                <ContentBlock className="mt-15">
                  <CheckboxBlock
                    label="Is Banner Active?"
                    id="isActive"
                    {...formik.getFieldProps('isActive')}
                    isChecked={formik.values.isActive}
                  />
                </ContentBlock>
              </Section>
              {
                bannerId > 0
                && (
                  <>
                    {/* BANNER ITEM DETAILS */}
                    <Section
                      hasNoContainer
                      className="content-container--card-style--with-shadow mt-30"
                    >
                      <ContentBlock>
                        <ContentHeader
                          title="Content Details"
                          headerSize="md"
                          className="alt-font fw-600 secondary--clr pb-15"
                        />
                      </ContentBlock>

                      <ContentBlock cols={4}>
                        <InputBlock
                          label="Slider Name"
                          placeholder="e.g. Primary Slider"
                          errorMessage={formik_bannerItem.errors.name}
                          inputState={`${helper.getInputClasses(formik_bannerItem, 'name')}`}
                          {...formik_bannerItem.getFieldProps('name')}
                        />
                      </ContentBlock>

                      <ContentBlock cols={4}>
                        <InputBlock
                          label="Title"
                          placeholder="e.g. Content Title"
                          errorMessage={formik_bannerItem.errors.title}
                          inputState={`${helper.getInputClasses(formik_bannerItem, 'title')}`}
                          {...formik_bannerItem.getFieldProps('title')}
                        />
                      </ContentBlock>

                      <ContentBlock cols={4}>
                        <SelectBlock
                          label="Alignment"
                          options={alignmentOptions}
                          value={alignment}
                          errorMessage={formik_bannerItem.errors.type}
                          onChange={(opt) => {
                            setAlignment(opt);
                            formik_bannerItem.setFieldValue('alignment', opt.value);
                          }}
                        />
                      </ContentBlock>

                      <ContentBlock>
                        <TextAreaBlock
                          label="Content Details"
                          placeholder="e.g. We provide top-rated construction services from home renovations to custom construction. Our team is dedicated to delivering construction services that are unmatched and guaranteed."
                          className="sm"
                          errorMessage={formik_bannerItem.errors.description}
                          inputState={`${helper.getInputClasses(formik_bannerItem, 'description')}`}
                          {...formik_bannerItem.getFieldProps('description')}
                        />
                      </ContentBlock>

                      <ContentBlock cols={3} className="mb-15">
                        <CheckboxBlock
                          label="Is Slider Active?"
                          id="banner-isActive"
                          {...formik_bannerItem.getFieldProps('isActive')}
                          isChecked={formik_bannerItem.values.isActive}
                        />
                      </ContentBlock>

                      <Section hasNoContainer className="mt-10">
                        <ContentBlock>
                          <ContentHeader
                            title="Banner Image"
                            subtitle="Upload an image for the banner"
                            headerSize="md"
                            subtitleSize="md"
                            className="alt-font fw-600 secondary--clr pb-15"
                          />
                        </ContentBlock>
                      </Section>
                      <ContentBlock>
                        {
                          formik_bannerItem.values.id === 0
                            ? (
                              <ContentBlock className="mt-15">
                                <FileUploadBlock
                                  onupdatefiles={setFile}
                                  ref={filePondRef}
                                />
                              </ContentBlock>
                            )
                            : (
                              <>
                                <CustomBlock className="flex-center pt-15 pb-30">
                                  <Image
                                    source={`${process.env.REACT_APP_API_URL}Attachments/${formik_bannerItem.values.imagePath}`}
                                    className="pl-10 pr-10"
                                  />
                                </CustomBlock>
                                <FileUploadBlock
                                  onupdatefiles={setFile}
                                  ref={filePondRef}
                                  labelIdle="Replace image"
                                />
                              </>
                            )
                        }
                      </ContentBlock>

                      {/* ACTIONS */}
                      <ContentBlock>
                        <ContentHeader
                          title="Actions"
                          headerSize="md"
                          className="alt-font fw-600 secondary--clr pb-15"
                        />
                      </ContentBlock>
                      <ContentBlock cols={3}>
                        <InputBlock
                          label="Primary Action Text"
                          placeholder="e.g. Learn More"
                          errorMessage={formik_bannerItem.errors.primaryButtonText}
                          inputState={`${helper.getInputClasses(formik_bannerItem, 'primaryButtonText')}`}
                          {...formik_bannerItem.getFieldProps('primaryButtonText')}
                        />
                      </ContentBlock>

                      <ContentBlock cols={3}>
                        <SelectBlock
                          label="Primary Action Type"
                          options={linkTypes}
                          isClearable
                          inputState={!formik_bannerItem.values.primaryButtonText ? 'disabled' : `${helper.getInputClasses(formik_bannerItem, 'primaryButtonActionType')}`}
                          errorMessage={formik_bannerItem.errors.primaryButtonActionType}
                          {...formik_bannerItem.getFieldProps('primaryButtonActionType')}
                          onMenuOpen={() => formik_bannerItem.setFieldTouched('primaryButtonActionType', true)}
                          value={primaryActionType}
                          onChange={(opt) => {
                            opt = opt === null ? [] : opt;
                            setPrimaryActionType(opt);
                            formik_bannerItem.setFieldValue('primaryButtonActionType', opt.value);
                          }}
                        />
                      </ContentBlock>

                      <ContentBlock cols={6}>
                        <InputBlock
                          label="Primary Action Link"
                          placeholder=""
                          errorMessage={formik_bannerItem.errors.primaryButtonLink}
                          inputState={!formik_bannerItem.values.primaryButtonText ? 'disabled' : `${helper.getInputClasses(formik_bannerItem, 'primaryButtonLink')}`}
                          {...formik_bannerItem.getFieldProps('primaryButtonLink')}
                        />
                      </ContentBlock>

                      <ContentBlock cols={3}>
                        <InputBlock
                          label="Secondary Action Text"
                          placeholder="e.g. Contact Us"
                          errorMessage={formik_bannerItem.errors.secondaryButtonText}
                          inputState={`${helper.getInputClasses(formik_bannerItem, 'secondaryButtonText')}`}
                          {...formik_bannerItem.getFieldProps('secondaryButtonText')}
                        />
                      </ContentBlock>

                      <ContentBlock cols={3}>
                        <SelectBlock
                          label="Secondary Action Type"
                          options={linkTypes}
                          isClearable
                          inputState={!formik_bannerItem.values.secondaryButtonText ? 'disabled' : `${helper.getInputClasses(formik_bannerItem, 'secondaryButtonActionType')}`}
                          errorMessage={formik_bannerItem.errors.secondaryButtonActionType}
                          {...formik_bannerItem.getFieldProps('secondaryButtonActionType')}
                          onMenuOpen={() => formik_bannerItem.setFieldTouched('secondaryButtonActionType', true)}
                          value={secondaryActionType}
                          onChange={(opt) => {
                            opt = opt === null ? [] : opt;
                            setSecondaryActionType(opt);
                            formik_bannerItem.setFieldValue('secondaryButtonActionType', opt.value);
                          }}
                        />
                      </ContentBlock>

                      <ContentBlock cols={6}>
                        <InputBlock
                          label="Secondary Action Link"
                          placeholder=""
                          errorMessage={formik_bannerItem.errors.secondaryButtonLink}
                          inputState={!formik_bannerItem.values.secondaryButtonText ? 'disabled' : `${helper.getInputClasses(formik_bannerItem, 'secondaryButtonLink')}`}
                          {...formik_bannerItem.getFieldProps('secondaryButtonLink')}
                        />
                      </ContentBlock>

                      <Section hasNoContainer className="mt-5">
                        <ContentBlock className="content-container--actions">
                          <Button
                            text="Clear"
                            className="grey--bg"
                            onClick={resetBannerItemEditFormFields}
                          />

                          <Button
                            text={formik_bannerItem.values.id === 0 ? 'Add New Banner' : 'Save Changes to Banner'}
                            className="warning--bg"
                            onClick={formik_bannerItem.submitForm}
                          />
                        </ContentBlock>
                      </Section>
                    </Section>
                    <Section
                      hasNoContainer
                      className="content-container--card-style--with-shadow mt-30"
                    >
                      <ContentBlock>
                        <ContentHeader
                          title="Banner Items"
                          headerSize="lg"
                          primaryButtonText="Sort Items"
                          primaryButtonOnClick={() => setIsSortModalOpen(true)}
                        />
                      </ContentBlock>

                      <ContentBlock>
                        <MUIDataTable
                          data={bannerModel.bannerItems}
                          columns={columns}
                          options={{
                            selectableRows: 'none',
                            download: true,
                            print: false,
                          }}
                        />
                      </ContentBlock>
                    </Section>
                  </>
                )
              }

              {/* PAGE ACTIONS */}
              <FixedActionsBar
                primaryActionText={formik.values.id === 0 ? 'Create' : 'Save Changes'}
                primaryActionColor="primary--bg"
                primaryActionOnClick={formik.handleSubmit}
                secondaryActionText="Cancel"
                secondaryActionOnClick={() => history.push('/cms/management/content-sections')}
                optionalActionText="Go to HomePage"
                optionalActionTo="/home"
              />
            </FormBlock>
          </ContentBlock>
        </Section>

        {/* SORT MODAL */}
        <ModalBlock
          hasCloseAction
          centered
          isVisible={isSortModalOpen}
          size="md"
          contentHeader="Sort Sections"
          primaryModalActionText="Sort"
          primaryModalActionOnClick={sortBannerItems}
          onHide={() => {
            setIsSortModalOpen(false);
          }}
        >
          <Section hasNoContainer>
            {
              sortList.length > 0
                ? (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(droppableProvided) => (
                        <div
                          ref={droppableProvided.innerRef}
                        >
                          {sortList.map((item, index) => (
                            <Draggable
                              key={item.id} draggableId={`${index}`}
                              index={index}
                            >
                              {(draggableProvided) => (
                                <div
                                  key={`index_${item.id}`}
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                  className="content-container--card-style light-grey--sbg pt-15 pb-15 pl-20 pr-20 mb-15"
                                >
                                  <p className="main-font fw-500">{item.title}</p>
                                </div>

                              )}
                            </Draggable>
                          ))}
                          {droppableProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <CustomBlock className="content-container--card-style light-grey--sbg pt-15 pb-15 pl-20 pr-20 mb-15">
                    <p className="main-font fw-500">No Items Available</p>
                  </CustomBlock>
                )
            }
          </Section>
        </ModalBlock>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alert.actions,
  ...confirmModalRedux.actions
})(BannerManagementPage);