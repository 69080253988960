/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
import CustomBlock from './CustomBlock';

const InfiniteScroller = ({ logos, imageHeight, imageSpacing, desaturateImages, className, scrollSpeed, scrollDirection }) => {
  // Concatenate logos to itself to simulate infinite scroll
  const logosForScrolling = [...logos, ...logos];
  const animationDuration = `${100 / scrollSpeed}s`;
  const animationName = scrollDirection === 'right' ? 'scroll-animation-reverse' : 'scroll-animation';

  return (
    <CustomBlock className={`infinite-scroller-container ${className}`}>
      <div className="scroll-content" style={{ animation: `${animationName} ${animationDuration} linear infinite` }}>
        {logosForScrolling.map((logo) => (
          <img
            src={logo.src}
            alt={logo.alt}
            title={logo.title}
            style={{
              height: `${imageHeight}px`,
              marginRight: `${imageSpacing}px`,
            }}
            className={`logo ${desaturateImages && 'logo__desaturate'}`}
          />
        ))}
      </div>
    </CustomBlock>
  );
};

InfiniteScroller.propTypes = {
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
  scrollSpeed: PropTypes.number,
  scrollDirection: PropTypes.oneOf(['left', 'right']),
};

InfiniteScroller.defaultProps = {
  className: '',
  scrollSpeed: 5,
  scrollDirection: 'left',
};

export default InfiniteScroller;