/* eslint-disable max-len */
// USED IN QUILL TEXT EDITOR COLOR OPTIONS & DEV ENV HEADER
const getThemeColors = () => {
  const root = document.documentElement;
  const colorsRef = {
    theme: {
      primaryColor: '--primary-color',
      primaryLighterColor: '--primary-lighter-color',
      primaryLightestColor: '--primary-lightest-color',
      secondaryColor: '--secondary-color',
      secondaryLighterColor: '--secondary-lighter-color',
      secondaryLightestColor: '--secondary-lightest-color',
      altColor: '--alt-color',
    },
    basic: {
      lighterColor: '--lighter-color',
      lightColor: '--light-color',
      whiteColor: '--white-color',
      darkColor: '--dark-color',
      blackColor: '--black-color',
    },
    grey: {
      lightestGreyColor: '--lightest-grey-color',
      lighterGreyColor: '--lighter-grey-color',
      lightGreyColor: '--light-grey-color',
      greyColor: '--grey-color',
      midGreyColor: '--mid-grey-color',
      darkGreyColor: '--dark-grey-color',
    },
    state: {
      successColor: '--success-color',
      dangerColor: '--danger-color',
      infoColor: '--info-color',
      warningColor: '--warning-color',
    },
  };

  const themeColorsArray = Object.keys(colorsRef).flatMap((group) => Object.keys(colorsRef[group]).map((key) => ({
    group,
    name: key
      .replace(/Color$/, '')
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .toLowerCase(),
    value: getComputedStyle(root).getPropertyValue(colorsRef[group][key]).trim(),
  })));

  return themeColorsArray;
};

export default { getThemeColors };