/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { IoMdAddCircle } from 'react-icons/io';
import { TbCheck, TbX } from 'react-icons/tb';
// CUSTOM COMPONENTS
import Overlay from '../../../../components/Overlay';
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
// SERVICES AND HELPERS
import * as blogService from '../../../../services/cms/blogService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const BlogListingPage = (props) => {
  const { showAlert, showConfirmModal, hideConfirmModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = () => {
    setIsLoading(true);
    blogService.getAllBlogs('').then((res) => {
      setBlogs(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const deleteBlog = (blogId) => {
    setIsLoading(true);

    blogService.deleteBlog(blogId).then((res) => {
      getBlogs();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true
  };

  const options = {
    selectableRows: 'none',
    download: true,
    print: false,
    jumpToPage: true,
    textLabels: { pagination: { jumpToPage: 'Page No:' } }
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="primary--bg ml-0"
              size="xxs"
              to={`/cms/management/blog/edit/${blogs[dataIndex].id}`}
            />

            <Button
              text="Remove"
              className="danger--bg"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Remove blog',
                  text: 'Are you sure you want to remove this blog?',
                  rightBtnText: 'Confirm',
                  btnAction: () => {
                    deleteBlog(blogs[dataIndex].id);
                  }
                });
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'Blog Name',
      options: columnOptions,
    },
    {
      name: 'addedBy',
      label: 'Added By',
      options: columnOptions,
    },
    {
      name: 'addedOn',
      label: 'Added On',
      options: columnOptions,
    },
    {
      name: 'isActive',
      label: 'Is Active?',
      options: {
        ...columnOptions,
        // customBodyRenderLite: (dataIndex) => (
        //   <p>{blogs[dataIndex].isActive ? 'Yes' : 'No'}</p>
        // )
        customBodyRenderLite: (dataIndex) => {
          const { isActive } = blogs[dataIndex];
          return (
            isActive ? <TbCheck size={26} strokeWidth={2} className="success--clr" /> : <TbX size={26} strokeWidth={2} className="danger--clr" />
          );
        }
      }
    },
  ];

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Blogs"
                headerSize="lg"
                primaryButtonText="New Blog"
                primaryButtonIconLeft={<IoMdAddCircle className={iconColor} size={iconSize} />}
                primaryButtonTo="/cms/management/blog"
              />

              <CustomBlock>
                <MUIDataTable
                  data={blogs}
                  columns={columns}
                  options={options}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });
export default connect(mapStateFromProps, { ...alert.actions, ...confirmModal.actions })(BlogListingPage);