/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
// ICONS
import {
  MdEmail,
  MdLocalPhone,
  MdLocationPin,
} from 'react-icons/md';
// COMPONENTS
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Fade } from 'react-awesome-reveal';
import Slider from 'react-slick';
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import Image from '../../../components/Image';
import InputBlock from '../../../components/InputBlock';
import TextAreaBlock from '../../../components/TextAreaBlock';
import Button from '../../../components/Button';
import Section from '../../../components/Section';
import ContentBlock from '../../../components/ContentBlock';
// ASSETS
import Logo from '../../../assets/client/logo-light.svg';
import BannerImage from '../../../assets/img/banner-image_default.png';
import IllustrationWave from '../../../assets/client/background-decoration_dark-light-waves.svg';
// SERVICES AND HELPERS
import * as helper from '../../../helpers/helper';
import constants from '../../../constants/constants';
import * as settingService from '../../../services/management/settingService';
import * as emailService from '../../../services/management/emailService';
// REDUX
import * as auth from '../../../redux/authRedux';
import * as alertToast from '../../../redux/alertToastRedux';

// const RotatingImageSlider = (props) => {
//   const { containerClassName } = props;
//   const rotatingImages = Array.from({ length: 5 }, (_, i) => `${constants.API_URL}Attachments/RotatingImages/image-${i + 1}.png`);

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     // customPaging: customPagingElement,
//     dotsClass: 'slick-dots custom-dots'
//   };

//   return (
//     <Slider
//       {...settings}
//       className={`${containerClassName}`}
//     >
//       {rotatingImages.map((imageUrl, index) => (
//         <img
//           src={imageUrl}
//           alt={`Slide ${index}`}
//           className="slick-image"
//         />
//       ))}
//     </Slider>
//   );
// };

const ContactPage = (props) => {
  const { showAlert, history, cmsInfo } = props;
  const captchaRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [googleMapURL, setGoogleMapURL] = useState([]);
  const [captchaToken, setCaptchaToken] = useState(null);
  // const siteKey = process.env.NODE_ENV === 'development' ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' : process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  useEffect(() => {
    fetchGoogleMapURL('GoogleMapURL');
  }, []);

  const fetchGoogleMapURL = (key) => {
    setIsLoading(true);
    settingService.getSettingByKey(key).then((res) => {
      setGoogleMapURL(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      message: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required').max(100),
      lastName: Yup.string().required('Last Name is required').max(100),
      email: Yup.string().email('Invalid email address').required('Email is required').max(100),
      phoneNumber: Yup.string().max(50),
      message: Yup.string().required('Message is required'),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (!captchaToken) {
        setSubmitting(false);
        return;
      }

      setIsLoading(true);

      const formattedValues = {
        ...values,
        reCAPTCHAToken: captchaToken,
      };

      try {
        await emailService.sendEmail(formattedValues);
        showAlert({ text: 'Success! Your message has been received.', state: 'success' });
        resetForm();
        setCaptchaToken(null);
        if (captchaRef.current) {
          captchaRef.current.reset();
        }
      } catch (ex) {
        showAlert({ text: ex.message, state: 'error' });
      } finally {
        setIsLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Contact"
        imageSource={BannerImage}
      />

      <CustomBlock className="main-content contact-page">
        {/* LOGO */}
        <CustomBlock className="content-section justify-content-center logo-section pb-20">
          <Fade delay={300}>
            <Image
              containerClassName="logo-container"
              className="logo"
              source={Logo}
            />
          </Fade>
          {/* <RotatingImageSlider
            containerClassName="image-container"
          /> */}
        </CustomBlock>

        {/* DETAILS */}
        <CustomBlock className="content-section flex-container">
          {/* COMPANY DETAILS SECTION */}
          <CustomBlock className="details-section">
            <Fade direction="up">
              <h2 className="size-lg primary--clr fw-700 mb-10">Contact</h2>
            </Fade>
            <CustomBlock className="contact">
              {cmsInfo.emailAddress && (
                <CustomBlock className="contact__row">
                  <CustomBlock className="contact__row__icon">
                    <MdEmail size="18" className="light--clr" />
                  </CustomBlock>
                  <CustomBlock className="d-flex flex-wrap text-break">
                    <p className="size-sm mid-grey--clr">
                      {cmsInfo.emailAddress}
                    </p>
                  </CustomBlock>
                </CustomBlock>
              )}

              {cmsInfo.phoneNumber && (
                <CustomBlock className="contact__row">
                  <CustomBlock className="contact__row__icon">
                    <MdLocalPhone size="18" className="light--clr" />
                  </CustomBlock>
                  <p className="size-sm mid-grey--clr">{cmsInfo.phoneNumber}</p>
                </CustomBlock>
              )}
            </CustomBlock>

            {(cmsInfo.streetAddress1 || cmsInfo.streetAddress2 || cmsInfo.city || cmsInfo.state || cmsInfo.zipCode) && (
              <CustomBlock className="location mt-30">
                <Fade direction="up" delay={300}>
                  <h2 className="size-lg primary--clr fw-700 mb-10">Location</h2>
                </Fade>
                <CustomBlock className="contact__row">
                  <CustomBlock className="contact__row__icon">
                    <MdLocationPin size="18" className="light--clr" />
                  </CustomBlock>
                  <p className="size-sm mid-grey--clr">
                    {cmsInfo.streetAddress1 && `${cmsInfo.streetAddress1}`}
                    {cmsInfo.streetAddress1 && cmsInfo.streetAddress2 && ', '}
                    {cmsInfo.streetAddress2 && `${cmsInfo.streetAddress2}`}
                    {(cmsInfo.streetAddress1 || cmsInfo.streetAddress2) && (cmsInfo.city || cmsInfo.state || cmsInfo.zipCode) && <br />}
                    {cmsInfo.city && `${cmsInfo.city}`}
                    {cmsInfo.city && cmsInfo.state && ', '}
                    {cmsInfo.state && `${cmsInfo.state}`}
                    {(cmsInfo.city || cmsInfo.state) && ' '}
                    {cmsInfo.zipCode && `${cmsInfo.zipCode}`}
                  </p>
                </CustomBlock>
              </CustomBlock>
            )}

            {(cmsInfo.primaryWeekDayHours || cmsInfo.secondaryWeekDayHours || cmsInfo.primaryWeekEndHours || cmsInfo.secondaryWeekEndHours) && (
              <CustomBlock className="hours mt-30">
                <Fade direction="up" delay={600}>
                  <h2 className="size-lg primary--clr fw-700 mb-10">Hours of Operation</h2>
                </Fade>
                <p className="size-sm mid-grey--clr">
                  {(cmsInfo.primaryWeekDayHours || cmsInfo.secondaryWeekDayHours) && (
                  <CustomBlock className="">
                    {cmsInfo.primaryWeekDayHours && `${cmsInfo.primaryWeekDayHours}`}
                    <br />
                    {cmsInfo.secondaryWeekDayHours && `${cmsInfo.secondaryWeekDayHours}`}
                  </CustomBlock>
                  )}
                </p>
                {(cmsInfo.primaryWeekEndHours || cmsInfo.secondaryWeekEndHours) && (
                <CustomBlock>
                  {cmsInfo.primaryWeekEndHours && (
                  <p>{cmsInfo.primaryWeekEndHours}</p>
                  )}
                  {cmsInfo.secondaryWeekEndHours && (
                  <p>{cmsInfo.secondaryWeekEndHours}</p>
                  )}
                </CustomBlock>
                )}
              </CustomBlock>
            )}
          </CustomBlock>

          {/* GOOGLE MAPS SECTION */}
          {(googleMapURL && googleMapURL !== null && googleMapURL !== '') && (
            <iframe
              className="google-map-section"
              title="Company Google Map"
              src={googleMapURL}
              allowFullScreen
            />
          )}
        </CustomBlock>

        {/* FORM CONTAINER */}
        <CustomBlock className="position-relative w-100">
          <img
            src={IllustrationWave}
            style={{
              position: 'absolute',
              top: '-12vw',
              left: '0',
              zIndex: '0',
              width: '100%',
              overflow: 'hidden',
            }}
            alt=""
          />
          
          {/* FORM */}
          <Form onSubmit={formik.handleSubmit}>
            <CustomBlock className="form-block content-section mx-auto roundest" style={{ position: 'relative', zIndex: '2' }}>
              <CustomBlock className="content-container--card-style lightest-grey--sbg">
                {/* FORM HEADER */}
                <CustomBlock className="form-header text-center mb-60">
                  <Fade direction="up" delay={100}>
                    <p className="size-xxl fw-700 dark--clr">Have Questions?</p>
                  </Fade>
                  <p className="size-lg fw-600 secondary--clr">
                    <Fade delay={300} cascade damping={1e-1}>
                      We&apos;re Here To Help
                    </Fade>
                  </p>
                </CustomBlock>
                
                {/* FORM */}
                <Container fluid className="p-0">
                  <Row>
                    <Col md="6" className="p-0">
                      <InputBlock
                        isRequired
                        label="First Name"
                        placeholder="John"
                        errorMessage={formik.errors.firstName}
                        inputState={helper.getInputClasses(formik, 'firstName')}
                        {...formik.getFieldProps('firstName')}
                        className="lighter-grey--sbg"
                      />
                    </Col>

                    <Col md="6" className="p-0">
                      <InputBlock
                        isRequired
                        label="Last Name"
                        placeholder="Smith"
                        errorMessage={formik.errors.lastName}
                        inputState={helper.getInputClasses(formik, 'lastName')}
                        {...formik.getFieldProps('lastName')}
                        className="lighter-grey--sbg"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" className="p-0">
                      <InputBlock
                        isRequired
                        label="Email Address"
                        placeholder="john.smith@example.com"
                        errorMessage={formik.errors.email}
                        inputState={helper.getInputClasses(formik, 'email')}
                        {...formik.getFieldProps('email')}
                        className="lighter-grey--sbg"
                      />
                    </Col>

                    <Col md="6" className="p-0">
                      <InputBlock
                        label="Phone Number"
                        placeholder="(555) 555-5555"
                        errorMessage={formik.errors.phoneNumber}
                        inputState={helper.getInputClasses(formik, 'phoneNumber')}
                        mask="(999) 999-9999"
                        {...formik.getFieldProps('phoneNumber')}
                        className="lighter-grey--sbg"
                      />
                    </Col>
                  </Row>

                  <Row className="p-0">
                    <Col md="12" className="p-0">
                      <TextAreaBlock
                        isRequired
                        label="Message"
                        placeholder="Provide your message here..."
                        errorMessage={formik.errors.message}
                        inputState={helper.getInputClasses(formik, 'message')}
                        {...formik.getFieldProps('message')}
                        className="lighter-grey--sbg"
                        labelColor="dark--clr"
                      />
                    </Col>
                  </Row>

                  {/* RECAPTCHA */}
                  <Row className="mt-30 p-0">
                    <CustomBlock className="input-block-container ml-auto">
                      {captchaToken == null ? (
                        <CustomBlock className="captcha">
                          <ReCAPTCHA
                            ref={captchaRef}
                            sitekey={siteKey}
                            onChange={setCaptchaToken}
                            onExpired={() => setCaptchaToken(null)}
                          />
                        </CustomBlock>
                      ) : (
                        <Button
                          type="submit"
                          isLoading={formik.isSubmitting}
                          isDisabled={formik.isSubmitting || !captchaToken}
                          text="Send Message"
                          className="success--bg"
                          size="sm"
                        />
                      )}
                    </CustomBlock>
                  </Row>
                </Container>

              </CustomBlock>
            </CustomBlock>
          </Form>
        </CustomBlock>
      </CustomBlock>

    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alertToast.actions
})(ContactPage);