/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
// COMPONENTS
import {
  Container, Row, Col, Carousel, ButtonGroup, Button as BootstrapButton
} from 'react-bootstrap';
import { TbMessage } from 'react-icons/tb';
import { SiZillow } from 'react-icons/si';
import Button from '../../../../../components/Button';
import CustomBlock from '../../../../../components/CustomBlock';
// ASSETS
// import BusinessGrowthBackgroundTexture from '../../../../../assets/img/background-texture_business-growth.png';
// SERVICES AND HELPERS
import * as featuredItemService from '../../../../../services/management/featuredItemService';

const listingTypeOptions = [
  { value: 'Residential', label: 'Residential' },
  { value: 'Commercial', label: 'Commercial' },
  { value: 'ResidentialLot', label: 'Residential Lot' },
  { value: 'CommercialLot', label: 'Commercial Lot' }
];

const FeaturedListingSection = () => {
  const [featuredItems, setFeaturedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedListingType, setSelectedListingType] = useState('Residential');

  // useEffect(() => {
  //   // if (featuredItems.length > 0) {
  //   console.log(featuredItems);
  //   // }
  // }, [selectedListingType]);

  useEffect(() => {
    getFeaturedListing(selectedListingType);
  }, [selectedListingType]);

  const getFeaturedListing = async (listingType) => {
    try {
      const allListings = await featuredItemService.getFeaturedItems();
      const activeListings = allListings.filter(
        (item) => item.isActive && item.listingType === listingType
      );
      setFeaturedItems(activeListings);
      console.log(`FEATURED LISTING ${selectedListingType} \n`, activeListings);
      if (activeListings.length > 0) {
        setSelectedItem(activeListings[0]);
      } else {
        setSelectedItem(null);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const handleSelectedListingType = (listingType) => {
    setSelectedListingType(listingType);
  };

  const handleSelect = (selectedIndex) => {
    if (featuredItems[selectedIndex]) {
      setSelectedItem(featuredItems[selectedIndex]);
    }
  };

  return (
    <CustomBlock className="content-section featured-listing-section flex-column align-items-start mx-auto px-0 py-30">
      <Row>
        <Col className="px-0">
          <Fade cascade triggerOnce>
            <h3 className="size-xl secondary-animated-gradient--clr fw-800 mb-2 lh-1">
              Your Local Real Estate Experts
            </h3>
            <p className="dark--clr size-sm mb-4">
              Our featured listings showcase the properties that can help take your business to the next level. Browse through and find your perfect match today.
            </p>
            
            <ButtonGroup size="sm" className="listing-types-group flex-wrap" aria-label="Listing Types">
              {listingTypeOptions.map((option, index) => (
                <BootstrapButton
                  key={option.value}
                  variant="outline-secondary"
                  onClick={() => handleSelectedListingType(option.value)}
                  className="py-2 px-3"
                  style={{
                    boxShadow: 'none',
                    borderTopLeftRadius: index === 0 ? '300px' : undefined,
                    borderBottomLeftRadius: index === 0 ? '300px' : undefined,
                    borderTopRightRadius: index === listingTypeOptions.length - 1 ? '300px' : undefined,
                    borderBottomRightRadius: index === listingTypeOptions.length - 1 ? '300px' : undefined,
                    backgroundColor: selectedListingType === option.value ? 'var(--primary-color)' : 'transparent',
                    color: selectedListingType === option.value ? '#fff' : 'var(--dark-color)',
                  }}
                >
                  {option.label}
                </BootstrapButton>
              ))}
            </ButtonGroup>
          </Fade>
        </Col>
      </Row>
      
      {featuredItems.length > 0 && (
        <Row className="w-100 mt-80" style={{ rowGap: '3rem' }}>
          {/* LEFT COLUMN: TEXT AND CAPTION INFO */}
          <Col
            md={12} lg={12} xl={6} className="d-flex flex-column justify-content-center px-0 h-100"
            style={{ minHeight: '100%' }}
          >

            {selectedItem && (
            <>
              <h3 className="size-mdlg primary--clr primary-animated-gradient--clr fw-800 lh-1">{selectedItem.title}</h3>
              {/* ADDRESS */}
              <CustomBlock className="d-flex flex-column mt-2">
                {selectedItem.addressLine1 && (
                  <span className="size-xs dark--clr lh-normal">
                    {selectedItem.addressLine1}
                    {selectedItem.addressLine2 && `, ${selectedItem.addressLine2}`}
                  </span>
                )}
                {(selectedItem.city || selectedItem.state || selectedItem.zipcode) && (
                  <CustomBlock>
                    <span className="size-xs dark--clr lh-normal">
                      {selectedItem.city}
                    </span>
                    {selectedItem.state && (
                    <span className="size-xs dark--clr lh-normal">
                      {`, ${selectedItem.state}`}
                    </span>
                    )}
                    {selectedItem.zipcode && (
                    <span className="size-xs dark--clr lh-normal">
                      {`, ${selectedItem.zipcode}`}
                    </span>
                    )}
                  </CustomBlock>
                )}
              </CustomBlock>
              {/* AMOUNT AND PROPERTY CODE */}
              {(selectedItem.amount || selectedItem.propertyCode) && (
                <CustomBlock className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  {selectedItem.amount && (
                  <p className="size-xs dark--clr lighter-grey--sbg round px-2">
                    <span className="size-xs dark--clr fw-300" style={{ opacity: '70%' }}>$</span>
                    {selectedItem.amount}
                  </p>
                  )}
                  {selectedItem.propertyCode && (
                  <p className="size-xs dark--clr lighter-grey--sbg round px-2">
                    <span className="size-xs dark--clr fw-300" style={{ opacity: '70%' }}>#</span>
                    <span>{selectedItem.propertyCode}</span>
                  </p>
                  )}
                </CustomBlock>
              )}
              {/* DESCRIPTION */}
              {selectedItem.description && (
                <CustomBlock
                  className="d-flex align-items-start flex-wrap gap-2 pt-3" style={{
                    minHeight: 'clamp(150px, 29vh, 300px)',
                    maxHeight: '60vh',
                    overflowY: 'auto'
                  }}
                >
                  <p className="size-sm dark--clr pr-3">
                    {selectedItem.description.split('\n').map((line, index) => (
                      <>
                        {line}
                        {index < selectedItem.description.split('\n').length - 1 && (
                          <div style={{ height: '8px' }} />
                        )}
                      </>
                    ))}
                  </p>
                </CustomBlock>
              )}
              {/* LISTING ACTIONS CONTAINER */}
              {(selectedItem.primaryButtonLink || selectedItem.secondaryButtonLink) && (
                <CustomBlock className="d-flex align-items-center flex-wrap gap-2">
                  <Fade cascade direction="left">
                    {/* Primary Button */}
                    {selectedItem.primaryButtonLink && (
                      selectedItem.primaryButtonLink.includes('zillow') ? (
                        <NavLink
                          to={selectedItem.primaryButtonLink || ''}
                          className="px-4 py-2 circle text-white d-flex align-items-center gap-2"
                          style={{ backgroundColor: '#016AFF' }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <SiZillow size={18} />
                          {selectedItem.primaryActionText}
                        </NavLink>
                      ) : (
                        <Button
                          to={selectedItem.primaryButtonLink}
                          className="px-4 py-2 circle primary--bg text-white d-flex align-items-center gap-2"
                          text={selectedItem.primaryActionText}
                        />
                      )
                    )}

                    {/* Secondary Button */}
                    {selectedItem.secondaryButtonLink && (
                      selectedItem.secondaryButtonLink.includes('zillow') ? (
                        <a
                          href={selectedItem.secondaryButtonLink || '/'}
                          className="px-4 py-2 circle text-white d-flex align-items-center gap-2 lighten-bg-hover"
                          style={{ backgroundColor: '#016AFF' }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <SiZillow size={18} />
                          {selectedItem.secondaryButtonText}
                        </a>
                      ) : (
                        <NavLink
                          to={selectedItem.secondaryButtonLink || '/'}
                          className="px-4 py-2 circle text-white secondary--bg d-flex align-items-center gap-2"
                        >
                          {selectedItem.secondaryButtonText}
                        </NavLink>
                      )
                    )}
                  </Fade>
                </CustomBlock>
              )}
            </>
            )}
          </Col>

          {/* RIGHT COLUMN: CAROUSEL */}
          <Col md={12} lg={12} xl={6} className="d-flex align-items-center justify-content-center px-0">
            <div style={{ width: '100%' }}>
              <div className="carousel-wrapper">
                <Carousel
                  indicators
                  interval={3000}
                  pause="hover"
                  onSelect={handleSelect}
                  className="custom-carousel"
                >
                  {featuredItems.map((item, index) => (
                    <Carousel.Item key={item.id} className="rounder">
                      <div
                        style={{
                          position: 'relative',
                          backgroundImage: `url(${process.env.REACT_APP_API_URL}Attachments${item.imageFilePath})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          width: '100%',
                          // height: '350px',
                          // height: '400px',
                          // aspectRatio: '4:3',
                          paddingTop: '60%',
                          maxHeight: '400px',
                          borderRadius: '10px',
                        }}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </div>
          </Col>
          
        </Row>
      )}
    </CustomBlock>
  );
};

export default FeaturedListingSection;