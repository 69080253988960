/* eslint-disable consistent-return */
// DEPENDENCIES
import React from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import CustomBlock from '../CustomBlock';
import SelectBlock from '../SelectBlock';
import Image from '../Image';
import Chip from '../Chip';

const TopHeaderSearchAndFilter = (props) => {
  const {
    hasSearchBlock,
    hasChipFilter,
    onScroll,
    history,
    auth
  } = props;

  const popularSelections = [
    { id: 1, name: 'Filter 1', url: '' },
    { id: 2, name: 'Filter 2', url: '' },
    { id: 3, name: 'Filter 3', url: '' },
    { id: 4, name: 'Filter 4', url: '' },
    { id: 5, name: 'Filter 5', url: '' },
  ];

  const formatOptionLabel = (option) => (
    <CustomBlock className="content-container--actions flex-start pl-0 mt-0 mb-10">
      <CustomBlock className="smaller-image-container">
        <Image source={`${process.env.REACT_APP_API_URL}Attachments/${option.filePath}`} />
      </CustomBlock>
      <p className="ml-15 fw-400">{option.label}</p>
    </CustomBlock>
  );

  const openInNewTab = (link) => {
    window.open(link, '_blank');
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      history.push('/deals', {
        predefinedFilter: {
          sortBy: '',
          priceRange: '',
          categories: null,
          searchTerm: e.target.value,
          productTypes: '',
          featuredSections: null,
          skip: 0,
        },
      });
      e.target.blur();
    }
  };

  return (
    <CustomBlock className={`top-header-container ${onScroll ? 'on-scroll' : ''}`}>
      {/* SEARCH BLOCK */}
      {hasSearchBlock && (
        <CustomBlock className="search-block">
          <SelectBlock
            isAsync
            placeholder="Search keywords & more..."
            onKeyDown={handleSearchKeyDown}
            autoBlur
            backgroundColor="white--sbg"
            value={[]}
            defaultOptions={[]}
            noOptionsMessage={(e) => (e.inputValue !== '' ? 'No Results Available' : 'Start typing and press enter to search')}
            formatOptionLabel={formatOptionLabel}
            onChange={(e) => history.push(`/deal/details/${e.value}`)}
          />
        </CustomBlock>
      )}

      {/* CHIP FILTER */}
      {hasChipFilter && (
        <CustomBlock className="chip-filter-block">
          <CustomBlock className="chips-container d-flex flex-wrap">
            {popularSelections.map((item) => (
              <Chip
                key={item.id}
                hasBorder
                label={item.name}
                onClick={() => {
                  openInNewTab(auth.authToken ? '/cms/management/pages' : '/login');
                }}
              />
            ))}
          </CustomBlock>
        </CustomBlock>
      )}
    </CustomBlock>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, null)(TopHeaderSearchAndFilter);