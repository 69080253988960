// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Image from './Image';
import TextBlock from './TextBlock';

const SelectablePreview = (props) => {
  const {
    title,
    titleColor,
    imageSource,
    onClick,
    isSelected,
  } = props;

  return (
    <CustomBlock>
      <TextBlock
        type="description"
        text={title}
        color={titleColor}
        className="fw-600 mb-5"
      />
      <button
        className={`selectable-preview ${isSelected ? 'selected' : ''}`}
        onClick={onClick}
      >
        <Image
          source={imageSource}
        />
      </button>
    </CustomBlock>
  );
};

SelectablePreview.propTypes = {
  // MAIN PROPS
  title: PropTypes.string,
  titleColor: PropTypes.string,
  imageSource: PropTypes.number.isRequired,

  // FUNCTION PROPS
  onClick: PropTypes.func,

  // BOOLEAN PROPS
  isSelected: PropTypes.bool
};

SelectablePreview.defaultProps = {
  // MAIN PROPS
  title: '',
  titleColor: '',

  // FUNCTION PROPS
  onClick: null,

  // BOOLEAN PROPS
  isSelected: false
};

export default SelectablePreview;