/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import jwt_decode from 'jwt-decode';
import { NavLink } from 'react-router-dom';
// ASSETS
import { FiLock, FiUser } from 'react-icons/fi';
import { HiOutlineMail } from 'react-icons/hi';
// COMPONENTS
import Button from './Button';
import InputBlock from './InputBlock';
import ModalBlock from './ModalBlock';
import CustomBlock from './CustomBlock';
import Logo from '../assets/img/logo_gradient.svg';
// SERVICES & HELPERS
import * as auth from '../redux/authRedux';
import * as alertToast from '../redux/alertToastRedux';
import * as userService from '../services/management/userService';
import * as helper from '../helpers/helper';

const MembersLoginModal = (props) => {
  const {
    showAlert,
    history,
    login,
    iconSize,
    onLoginSuccess,
    isVisible,
    setIsVisible,
  } = props;
  const [currentView, setCurrentView] = useState('login');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);

  const loginFormik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required('Email is required').email('Invalid email format'),
      password: Yup.string().required('Password is required').min(8, 'Minimum password length must be at least 8 characters'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const res = await userService.login(values);
        const decodedToken = jwt_decode(res.token);
        login(res);
        if (onLoginSuccess) onLoginSuccess(decodedToken);
        history.push(decodedToken.AccessLevel >= 40 ? '/admin/management/users' : {
          pathname: '/geaux-engage-member-profile',
          state: { userId: decodedToken.UserId },
        });
      } catch (ex) {
        showAlert({ text: ex.message, state: 'error' });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const resetPasswordFormik = useFormik({
    initialValues: {
      email: '',
      resetCode: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required('Email is required').email('Invalid email format'),
      resetCode: Yup.string().required('Reset code is required'),
      newPassword: Yup.string().required('New password is required').min(8, 'Minimum password length must be at least 8 characters'),
      confirmPassword: Yup.string().required('Confirm your new password').oneOf([Yup.ref('newPassword')], 'Passwords do not match'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (currentView === 'password-reset') {
          await userService.sendPasswordRecoveryEmail(values.email);
          showAlert({ text: 'Password recovery email sent.', state: 'success' });
          setCurrentView('password-recover');
        } else {
          await userService.resetPassword({
            email: values.email,
            resetCode: values.resetCode,
            newPassword: values.newPassword,
          });
          showAlert({ text: 'Password reset successful.', state: 'success' });
          setCurrentView('login');
        }
      } catch (ex) {
        showAlert({ text: ex.response?.data?.message || ex.message, state: 'error' });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleLoginModalClose = () => {
    setIsVisible(false);
    setCurrentView('login');
    loginFormik.resetForm();
    resetPasswordFormik.resetForm();
  };

  const renderLoginView = () => (
    <CustomBlock className="mt-10 mb-40">
      <form onSubmit={loginFormik.handleSubmit}>
        <InputBlock
          id="email"
          label="Email"
          placeholder="Enter email"
          errorMessage={loginFormik.touched.email && loginFormik.errors.email}
          inputState={`${helper.getInputClasses(loginFormik, 'email')}`}
          {...loginFormik.getFieldProps('email')}
          iconLeft={<FiUser size={iconSize} className="dark-grey--clr" />}
          className="input-block padded"
        />
        <InputBlock
          type={passwordVisible ? 'text' : 'password'}
          label="Password"
          placeholder="Enter password"
          iconLeft={<FiLock size={iconSize} className="dark-grey--clr" />}
          inputRightButtonText={passwordVisible ? 'Hide' : 'Show'}
          inputRightButtonOnClick={() => setPasswordVisible(!passwordVisible)}
          {...loginFormik.getFieldProps('password')}
          errorMessage={loginFormik.touched.password && loginFormik.errors.password}
          inputState={`${helper.getInputClasses(loginFormik, 'password')}`}
          className="input-block padded"
        />
      </form>

      <CustomBlock className="d-flex flex-column align-items-start mt-20">
        <Button
          type="link"
          text={(
            <p className="size-xs dark-grey--clr primary-lighter--hover-clr primary-lighter--hover-underline mb-20">
              Forgot Your Password?
            </p>
          )}
          className="dark--clr p-0"
          onClick={() => setCurrentView('password-reset')}
        />
        <NavLink
          to="/geaux-engage-registration"
          className="button dark--clr p-0"
          onClick={() => setIsVisible(false)}
        >
          <p className="size-xs dark-grey--clr primary-lighter--hover-clr primary-lighter--hover-underline">
            Not a member?
            <span className="size-xs fw-700"> Create Account</span>
          </p>
        </NavLink>
      </CustomBlock>
    </CustomBlock>
  );

  const renderPasswordResetView = () => (
    <CustomBlock className="mt-10 mb-40">
      <CustomBlock className="d-flex flex-column align-items-start mb-30">
        <p className="dark-grey--clr mb-10">
          Enter your membership email to receive a reset code. Use the code and the link below to reset your password.
        </p>

        <Button
          type="link"
          text={(
            <p className="size-xs dark-grey--clr primary-lighter--hover-clr primary-lighter--hover-underline">
              Have a reset code?
              <span className="size-xs fw-700"> Reset Password</span>
            </p>
          )}
          className="animate-onhover-primary dark--clr p-0"
          onClick={() => setCurrentView('password-recover')}
        />
      </CustomBlock>

      <form onSubmit={resetPasswordFormik.handleSubmit}>
        <InputBlock
          id="email"
          label="Email Address"
          placeholder="Enter your email address"
          errorMessage={resetPasswordFormik.touched.email && resetPasswordFormik.errors.email}
          inputState={`${helper.getInputClasses(resetPasswordFormik, 'email')}`}
          {...resetPasswordFormik.getFieldProps('email')}
          iconLeft={<HiOutlineMail size={iconSize} className="dark-grey--clr" />}
          autoComplete="off"
          className="disabled border-0"
          isDisabled
        />
      </form>
    </CustomBlock>
  );

  const renderPasswordRecoverView = () => (
    <CustomBlock className="mt-10 mb-40">
      <form onSubmit={resetPasswordFormik.handleSubmit}>
        <InputBlock
          label="Reset Code"
          type="number"
          placeholder="Enter your reset code"
          errorMessage={resetPasswordFormik.touched.resetCode && resetPasswordFormik.errors.resetCode}
          inputState={`${helper.getInputClasses(resetPasswordFormik, 'resetCode')}`}
          {...resetPasswordFormik.getFieldProps('resetCode')}
          className="disabled border-0"
          isDisabled
        />
        <InputBlock
          label="New Password"
          type={passwordVisible ? 'text' : 'password'}
          placeholder="Enter new password"
          inputRightButtonText={passwordVisible ? 'Hide' : 'Show'}
          inputRightButtonOnClick={() => setPasswordVisible(!passwordVisible)}
          errorMessage={resetPasswordFormik.touched.newPassword && resetPasswordFormik.errors.newPassword}
          inputState={`${helper.getInputClasses(resetPasswordFormik, 'newPassword')}`}
          {...resetPasswordFormik.getFieldProps('newPassword')}
          autoComplete="new-password"
          name={`input-${Math.random()}`}
          className="disabled border-0"
          isDisabled
        />
        <InputBlock
          label="Confirm Password"
          type={passwordConfirmVisible ? 'text' : 'password'}
          placeholder="Confirm new password"
          inputRightButtonText={passwordConfirmVisible ? 'Hide' : 'Show'}
          inputRightButtonOnClick={() => setPasswordConfirmVisible(!passwordConfirmVisible)}
          errorMessage={resetPasswordFormik.touched.confirmPassword && resetPasswordFormik.errors.confirmPassword}
          inputState={`${helper.getInputClasses(resetPasswordFormik, 'confirmPassword')}`}
          {...resetPasswordFormik.getFieldProps('confirmPassword')}
          autoComplete="new-password"
          className="disabled border-0"
          isDisabled
        />
      </form>
    </CustomBlock>
  );

  return (
    <ModalBlock
      hasCloseAction
      isVisible={isVisible}
      size="md"
      contentHeader={currentView === 'login' ? 'Members Login' : currentView === 'password-reset' ? 'Password Recovery' : 'Password Reset'}
      primaryModalActionText={currentView === 'login' ? 'Login' : 'Reset Password'}
      primaryModalActionType="submit"
      primaryModalActionColor="success--bg"
      primaryModalActionOnClick={currentView === 'login' ? loginFormik.handleSubmit : resetPasswordFormik.handleSubmit}
      secondaryModalActionText={currentView === 'login' ? 'Cancel' : 'Return to Login'}
      secondaryModalActionColor="mid-grey--bg"
      secondaryModalActionOnClick={currentView === 'login' ? handleLoginModalClose : () => {
        setCurrentView('login');
        resetPasswordFormik.resetForm();
      }}
      onHide={handleLoginModalClose}
      isEnterKeydownEnabled
      isLoading={loginFormik.isSubmitting || resetPasswordFormik.isSubmitting}
      buttonSize="xs"
    >
      {currentView === 'login' && renderLoginView()}
      {currentView === 'password-reset' && renderPasswordResetView()}
      {currentView === 'password-recover' && renderPasswordRecoverView()}
    </ModalBlock>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions, ...alertToast.actions })(MembersLoginModal);