/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
// CUSTOM COMPONENTS
import { TbExternalLink, TbSearch } from 'react-icons/tb';
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import Button from '../../../components/Button';
// ASSETS
import BannerImage from '../../../assets/img/banner-image_default.png';
// SERVICES AND HELPERS

const PropertySearchPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeIframe, setActiveIframe] = useState('');

  const MLSSearchURL = 'https://mlsbox.paragonrels.com/idx/idx.aspx?RMLS_SESSION_GUID=%7b1c588142-f2dd-4af1-aabd-2533af62362f%7d&MLS=MLSBOX&SUBSCRIBER=b7eebed1-f916-41c8-9964-f1c399745e3a&&MLSSearch=1';
  const OtherCriteriaSearchURL = 'https://mlsbox.paragonrels.com/idx/idx.aspx?RMLS_SESSION_GUID=%7ba51b67fd-c039-4a99-8ca4-869acde23c4d%7d&MLS=MLSBOX&SUBSCRIBER=b7eebed1-f916-41c8-9964-f1c399745e3a&';

  const toggleIframe = (iframeName) => {
    setActiveIframe((prev) => (prev === iframeName ? '' : iframeName));
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner isDynamicBanner isTitleUppercase title="Search Properties" imageSource={BannerImage} />

      <CustomBlock className="main-content property-search mb-200">
        <CustomBlock className="content-section align-items-center mx-auto">
          <CustomBlock className="d-flex flex-column">
            <h3 className="size-xl primary--clr fw-800 mb-20">Start Your Property Search Today</h3>
            <Fade cascade>
              <p className="dark--clr size-md mb-10">
                Discover a wide range of properties and find the perfect home or investment that matches your needs. Begin your search by MLS number or explore more options with advanced criteria.
              </p>
            </Fade>
            <CustomBlock className="d-flex align-items-center flex-wrap gap-2 mt-20">
              <Fade cascade direction="left" triggerOnce>
                <Button
                  className={`circle grey--bg lh-normal ${activeIframe === 'mls' && 'success--bg'}`}
                  size="xs"
                  onClick={() => toggleIframe('mls')}
                  iconLeft={<TbSearch strokeWidth={2.4} className="size-xxs mr-2" />}
                  text="Search by MLS or Address"
                />
                <Button
                  className={`circle grey--bg lh-normal ${activeIframe === 'other' && 'success--bg'}`}
                  size="xs"
                  onClick={() => toggleIframe('other')}
                  iconLeft={<TbSearch strokeWidth={2.4} className="size-xxs mr-2" />}
                  text="Search Criteria"
                />
              </Fade>
            </CustomBlock>
          </CustomBlock>
        </CustomBlock>

        {activeIframe === 'mls' && (
          <CustomBlock className="content-section flex-column align-items-start">
            <Fade cascade className="w-100">
              <h3 className="size-lg primary--clr secondary-animated-gradient--clr fw-800 mb-10">Search by MLS or Address</h3>
              <CustomBlock className="d-flex align-items-center flex-wrap gap-2 mb-20">
                <Fade cascade triggerOnce>
                  <a href={OtherCriteriaSearchURL} className="button lh-normal dark--bg white--clr size-xxxs circle" target="_blank" rel="noopener noreferrer">
                    <TbExternalLink strokeWidth={2.4} className="size-xxs mr-2" />
                    Open in New Tab
                  </a>
                </Fade>
              </CustomBlock>
              <CustomBlock className="w-100" style={{ border: '3px solid #495d6d', borderRadius: '10px', overflow: 'hidden', boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px' }}>
                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                  <iframe
                    src={MLSSearchURL}
                    title="MLS Search"
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </CustomBlock>
            </Fade>
          </CustomBlock>
        )}

        {activeIframe === 'other' && (
          <CustomBlock className="content-section flex-column align-items-start">
            <Fade cascade className="w-100">
              <h3 className="size-lg primary--clr secondary-animated-gradient--clr fw-800 mb-10">Search by Other Criteria</h3>
              <CustomBlock className="d-flex align-items-center flex-wrap gap-2 mb-20">
                <Fade cascade triggerOnce>
                  <a href={OtherCriteriaSearchURL} className="button lh-normal dark--bg white--clr size-xxxs circle" target="_blank" rel="noopener noreferrer">
                    <TbExternalLink strokeWidth={2.4} className="size-xxs mr-2" />
                    Open in New Tab
                  </a>
                </Fade>
              </CustomBlock>
              <CustomBlock className="w-100" style={{ border: '3px solid #495d6d', borderRadius: '10px', overflow: 'hidden', boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px' }}>
                <div style={{ width: '100%', height: '1000px', overflow: 'hidden' }}>
                  <iframe
                    src={OtherCriteriaSearchURL}
                    title="Other Criteria Search"
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </CustomBlock>
            </Fade>
          </CustomBlock>
        )}
      </CustomBlock>
    </>
  );
};

export default PropertySearchPage;