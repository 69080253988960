/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
// COMPONENTS
import {
  Container, Row, Col
} from 'react-bootstrap';
import CustomBlock from '../../../../../components/CustomBlock';

const ServicesIntroSection = (props) => {
  const [featuredItems, setFeaturedItems] = useState([]);

  return (
    <CustomBlock className="content-section services-intro-section d-flex flex-column align-items-start px-0 py-30">
      <Row>
        <Fade cascade>
          <Col className="mb-60 px-0">
            <h3 className="size-xl primary--clr fw-800 mb-2 lh-1">
              Services Tailored to You
            </h3>
            <p className="dark--clr size-sm">
              Whether you&apos;re buying, selling, or managing properties, our local expertise and personalized service ensure a seamless experience from start to finish.
            </p>
          </Col>
        </Fade>
      </Row>

      <Container fluid className="mx-0 px-0">
        <Fade cascade>
          <Row className="g-3" style={{ rowGap: '2rem' }}>
            {/* Buying Services */}
            <Col
              sm={12} md={6} lg={6} xl={4}
              className="d-flex"
            >
              <CustomBlock className="card--1 rounder p-4 flex-grow-1">
                <h3 className="size-mdlg mid-grey--clr fw-600 mb-2 lh-1">
                  Find Your Perfect Property
                </h3>
                <p className="dark--clr size-xs mid-grey--clr">
                  Searching for a new home or investment? We&apos;ll guide you through the buying process, ensuring you find the property that meets your needs, with expert advice at every step.
                </p>
                <Fade direction="up">
                  <NavLink
                    to="/crossroads-realty-buying-services"
                    className="button--xs px-4 py-2 circle alt--bg primary--bg text-white d-flex align-items-center gap-2 fit-content mt-4"
                  >
                    Buying Services
                  </NavLink>
                </Fade>
              </CustomBlock>
            </Col>

            {/* Selling Services */}
            <Col
              sm={12} md={6} lg={6} xl={4}
              className="d-flex"
            >
              <CustomBlock className="card--1 rounder p-4 flex-grow-1">
                <h3 className="size-mdlg mid-grey--clr fw-600 mb-2 lh-1">
                  Maximize Your Sale Value
                </h3>
                <p className="dark--clr size-xs mid-grey--clr">
                  Ready to sell? We leverage local expertise and market insights to ensure you get the best price for your property, with minimal hassle and maximum return.
                </p>
                <Fade direction="up">
                  <NavLink
                    to="/crossroads-realty-selling-services"
                    className="button--xs px-4 py-2 circle alt--bg secondary--bg text-white d-flex align-items-center gap-2 fit-content mt-4"
                  >
                    Selling Services
                  </NavLink>
                </Fade>
              </CustomBlock>
            </Col>

            {/* Property Management Services */}
            <Col
              sm={12} md={12} lg={12} xl={4}
              className="d-flex"
            >
              <CustomBlock className="card--1 rounder p-4 flex-grow-1">
                <h3 className="size-mdlg mid-grey--clr fw-600 mb-2 lh-1">
                  Stress-Free Property Management
                </h3>
                <p className="dark--clr size-xs mid-grey--clr">
                  Let us handle your property management needs, from leasing and maintenance to rent collection and tenant support. We take care of everything so you don&apos;t have to.
                </p>
                <Fade direction="up">
                  <NavLink
                    to="/crossroads-realty-property-management-services"
                    className="button--xs px-4 py-2 circle alt--bg text-white d-flex align-items-center gap-2 fit-content mt-4"
                  >
                    Property Management
                  </NavLink>
                </Fade>
              </CustomBlock>
            </Col>
          </Row>
        </Fade>
      </Container>

    </CustomBlock>
  );
};

export default ServicesIntroSection;