/* eslint-disable max-classes-per-file */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable max-len */
import React, {
  useRef, useEffect, useState
} from 'react';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
// import { rest } from 'lodash';
// SERVICES & HELPERS
import themeHelper from '../helpers/themeHelper';

// QUILL IMPORTS
const Block = Quill.import('blots/block');

// CUSTOM BLOT - BLOCKQUOTE
class Blockquote extends Block {}
Blockquote.blotName = 'blockquote';
Blockquote.tagName = 'blockquote';
Quill.register(Blockquote, true);
Quill.register('modules/imageResize', ImageResize);

const QuillTextEditor = (props) => {
  const { value, onChange, placeholder } = props;
  const quillRef = useRef(null);
  const quillContainerRef = useRef(null);
  const [themeColors, setThemeColors] = useState([]);

  const [modules, setModules] = useState({
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ color: [] }, { background: [] }], // INITALLY EMPTY, UPDATED BELOW
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'image', 'video'],
        ['clean']
      ],
    },
    clipboard: { matchVisual: false },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    },
  });

  useEffect(() => {
    const fetchThemeColors = () => {
      const themeColorsData = themeHelper.getThemeColors();
      const formattedThemeColors = themeColorsData.map((color) => ({ color: [color.value] }));
      setThemeColors(formattedThemeColors);
    };

    fetchThemeColors();
  }, []);

  useEffect(() => {
    if (themeColors.length > 0) {
      setModules((prevModules) => ({
        ...prevModules,
        toolbar: {
          ...prevModules.toolbar,
          container: prevModules.toolbar.container.map((item) => {
            if (Array.isArray(item) && item.length > 0 && item[0].color) {
              return [{ color: themeColors.map((option) => option.color[0]) }, { background: themeColors.map((option) => option.color[0]) }];
            }
            return item;
          }),
        },
      }));
    }
  }, [themeColors]);

  const formats = [
    'header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'link', 'image', 'video', 'align',
    'width', 'height', 'style', 'color', 'background',
  ];

  useEffect(() => {
    let initialTop = 0;
    let quillWidth = 0;

    const applyStyles = (isFixed) => {
      const toolbar = quillContainerRef.current.querySelector('.ql-toolbar');
      toolbar.style.position = isFixed ? 'fixed' : '';
      toolbar.style.top = isFixed ? '0' : '';
      toolbar.style.width = isFixed ? '100%' : '';
      toolbar.style.marginTop = isFixed ? '5px' : '';
      toolbar.style.zIndex = isFixed ? '1000' : '';
      toolbar.style.backgroundColor = '#FFFFFF';
      toolbar.style.boxShadow = isFixed ? '#66666694 0px 4px 6px' : 'none';
      toolbar.style.borderRadius = isFixed ? '6px' : '6px 6px 0 0';
      toolbar.style.transform = isFixed ? 'scale(1.05)' : '';
      toolbar.style.maxWidth = `${quillWidth}px`;
    };

    const handleScroll = () => {
      if (quillContainerRef.current) {
        const toolbar = quillContainerRef.current.querySelector('.ql-toolbar');
        const quill = quillContainerRef.current.querySelector('.quill');

        if (initialTop === 0 || quillWidth === 0) {
          initialTop = toolbar.offsetTop;
          quillWidth = quill.offsetWidth;
          applyStyles(window.scrollY >= initialTop);
        } else {
          applyStyles(window.scrollY >= initialTop);
        }
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const imageHandler = () => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/svg+xml, image/png, image/jpeg');
      input.click();
  
      input.onchange = async () => {
        const file = input.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const quillEditor = quillRef.current.getEditor();
            const range = quillEditor.getSelection();
            const index = range ? range.index : quillEditor.getLength();
  
            if (file.type === 'image/svg+xml') {
              const svgContent = e.target.result;
              quillEditor.insertEmbed(index, 'image', `data:image/svg+xml;base64,${btoa(svgContent)}`, 'user');
            } else {
              quillEditor.insertEmbed(index, 'image', e.target.result);
            }
            quillEditor.setSelection(index + 1);
          };

          if (file.type === 'image/svg+xml') {
            reader.readAsText(file);
          } else {
            reader.readAsDataURL(file);
          }
        }
      };
    };

    setModules((prevModules) => ({
      ...prevModules,
      toolbar: {
        ...prevModules.toolbar,
        handlers: {
          ...prevModules.toolbar.handlers,
          image: imageHandler
        },
      },
    }));
  }, []);

  return (
    <div ref={quillContainerRef} className="quill-container">
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
      />
    </div>
  );
};

export default QuillTextEditor;