// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// COMPONENTS
import { Link } from 'react-router-dom';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import TextHeader from './TextHeader';
import Button from './Button';

const ContentHeader = (props) => {
  const {
    containerClassName,
    title,
    subtitle,
    headerSize,
    primaryButtonText,
    primaryButtonBackgroundColor,
    primaryButtonIconLeft,
    secondaryButtonText,
    secondaryButtonBackgroundColor,
    secondaryButtonIconLeft,
    buttonSize,
    headerIcon,
    headerIconContainerClass,
    primaryButtonTo,
    secondaryButtonTo,
    headerIconTo,
    primaryButtonOnClick,
    secondaryButtonOnClick,
    headerIconOnClick,
    isPrimaryLinkTargetBlank,
    isSecondaryLinkTargetBlank,
    isButtonTextUppercase,
    isPrimaryButtonLoading,
    isPrimaryButtonDisabled,
    isSecondaryButtonLoading,
    isSecondaryButtonDisabled,
    isTitleCentered
  } = props;

  return (
    <CustomBlock className={`content-header ${isTitleCentered ? 'flex-center' : ''} ${containerClassName}`}>
      <TextHeader
        {...props}
        title={title}
        subtitle={subtitle}
        size={headerSize}
      />
      <CustomBlock className="content-header-button-actions">
        {
          secondaryButtonText !== ''
          && (
            <Button
              text={secondaryButtonText}
              to={secondaryButtonTo}
              onClick={secondaryButtonOnClick}
              size={buttonSize}
              openInNewTab={isSecondaryLinkTargetBlank}
              className={secondaryButtonBackgroundColor}
              uppercaseText={isButtonTextUppercase}
              isLoading={isSecondaryButtonLoading}
              isDisabled={isSecondaryButtonDisabled}
              iconLeft={secondaryButtonIconLeft}
            />
          )
        }

        {
          primaryButtonText !== ''
          && (
            <Button
              text={primaryButtonText}
              to={primaryButtonTo}
              onClick={primaryButtonOnClick}
              openInNewTab={isPrimaryLinkTargetBlank}
              className={primaryButtonBackgroundColor}
              size={buttonSize}
              uppercaseText={isButtonTextUppercase}
              isLoading={isPrimaryButtonLoading}
              isDisabled={isPrimaryButtonDisabled}
              iconLeft={primaryButtonIconLeft}
            />
          )
        }
      </CustomBlock>
      {
        headerIcon
        && (
          <CustomBlock className={`content-header-icon-actions ${headerIconContainerClass}`}>
            {
              headerIconTo
                ? (
                  <Link
                    to={headerIconTo}
                    onClick={headerIconOnClick || (() => { })}
                  >
                    {headerIcon}
                  </Link>
                )
                : (
                  <button
                    onClick={headerIconOnClick || (() => { })}
                  >
                    {headerIcon}
                  </button>
                )
            }
          </CustomBlock>
        )
      }
    </CustomBlock>
  );
};

ContentHeader.propTypes = {
  // CONTAINER PROPS
  containerClassName: PropTypes.string,

  // HEADER PROPS
  title: PropTypes.string,
  subtitle: PropTypes.string,
  headerSize: PropTypes.string,

  // BUTTON PROPS - PRIMARY
  primaryButtonText: PropTypes.string,
  primaryButtonBackgroundColor: PropTypes.string,
  primaryButtonIconLeft: PropTypes.object,

  // BUTTON PROPS - SECONDARY
  secondaryButtonText: PropTypes.string,
  secondaryButtonBackgroundColor: PropTypes.string,
  secondaryButtonIconLeft: PropTypes.object,

  // BUTTON PROPS - SHARED
  buttonSize: PropTypes.string,

  // HEADER ICON
  headerIcon: PropTypes.any,
  headerIconContainerClass: PropTypes.string,

  // NAVIGATION PROPS
  primaryButtonTo: PropTypes.string,
  secondaryButtonTo: PropTypes.string,
  headerIconTo: PropTypes.string,

  // FUNCTION PROPS
  primaryButtonOnClick: PropTypes.func,
  secondaryButtonOnClick: PropTypes.func,
  headerIconOnClick: PropTypes.func,

  // BOOLEAN PROPS
  isPrimaryLinkTargetBlank: PropTypes.bool,
  isSecondaryLinkTargetBlank: PropTypes.bool,
  isButtonTextUppercase: PropTypes.bool,
  isPrimaryButtonLoading: PropTypes.bool,
  isPrimaryButtonDisabled: PropTypes.bool,
  isSecondaryButtonLoading: PropTypes.bool,
  isSecondaryButtonDisabled: PropTypes.bool,
  isTitleCentered: PropTypes.bool,
};

ContentHeader.defaultProps = {
  // CONTAINER PROPS
  containerClassName: '',

  // HEADER PROPS
  title: '',
  subtitle: '',
  headerSize: '',

  // BUTTON PROPS - PRIMARY
  primaryButtonText: '',
  primaryButtonBackgroundColor: 'primary--bg',
  primaryButtonIconLeft: null,

  // BUTTON PROPS - SECONDARY
  secondaryButtonText: '',
  secondaryButtonBackgroundColor: 'secondary--bg',
  secondaryButtonIconLeft: null,

  // BUTTON PROPS - SHARED
  buttonSize: '',

  // HEADER ICON
  headerIcon: null,

  // NAVIGATION PROPS
  primaryButtonTo: '',
  secondaryButtonTo: '',
  headerIconTo: '',
  headerIconContainerClass: '',

  // FUNCTION PROPS
  primaryButtonOnClick: () => { },
  secondaryButtonOnClick: () => { },
  headerIconOnClick: () => { },

  // BOOLEAN PROPS
  isPrimaryLinkTargetBlank: false,
  isSecondaryLinkTargetBlank: false,
  isButtonTextUppercase: false,
  isPrimaryButtonLoading: false,
  isPrimaryButtonDisabled: false,
  isSecondaryButtonLoading: false,
  isSecondaryButtonDisabled: false,
  isTitleCentered: false,
};

export default ContentHeader;