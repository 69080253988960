/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
// DEPENDENCIES
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
// ICONS
import { TbHomeSearch, TbLogin2 } from 'react-icons/tb';
// COMPONENTS
import {
  Container, Row, Col
} from 'react-bootstrap';
import CustomBlock from '../../../../components/CustomBlock';
// ASSETS
import HeroImage from '../../../../assets/client/hero-image.png';

const CustomBannerInsert = (props) => {
  const { history, offsetValue } = props;

  return (
    <CustomBlock
      className="custom-banner position-relative d-flex flex-column justify-content-center align-items-start"
      style={{
        background: `url('${HeroImage}'), linear-gradient(45deg, #121516, #212528, #212528)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: `${offsetValue * 0.50}px`,
        backgroundBlendMode: 'color-burn',
      }}
    >
      <Container>
        <Row className="">
          <Col md={12} className="px-0">
            <Fade cascade>
              <p className="size-xxxxl fw-600 font--client secondary-animated-gradient--clr mb-2">Crossroads Realty</p>
              <p className="size-mdlg secondary-animated-gradient--clr mb-3" style={{ fontStyle: 'italic' }}>Where Every Path Leads Home.</p>
              
              <p className="size-md primary-animated-gradient--clr mb-5">
                Local experts. Trusted neighbors.
                <br />
                Whether you&apos;re buying, selling, or managing, we make your real estate journey smooth and stress-free.
              </p>
            </Fade>
              
            <CustomBlock className="d-flex align-items-start flex-wrap gap-3">
              <Fade cascade direction="up">
                <a
                  href="https://crossroadsrealty.managebuilding.com/Resident/portal/login"
                  className="golden-button d-flex align-items-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="d-flex align-items-center gap-2 golden-text size-xs font--main fw-600">
                    <TbLogin2
                      strokeWidth={2.2}
                      size={20}
                      style={{ filter: 'drop-shadow(0 2px 2px #fae385) drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16)) drop-shadow(0 3px 6px rgba(110, 80, 20, 0.4))' }}
                    />
                    Resident Center
                  </span>
                </a>

                <NavLink
                  to="/crossroads-realty-property-search"
                  className="golden-button d-flex align-items-center"
                >
                  <span className="d-flex align-items-center gap-2 golden-text size-xs font--main fw-600">
                    <TbHomeSearch
                      strokeWidth={2.2}
                      size={20}
                      style={{ filter: 'drop-shadow(0 2px 2px #fae385) drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16)) drop-shadow(0 3px 6px rgba(110, 80, 20, 0.4))' }}
                    />
                    Property Search
                  </span>
                </NavLink>
              </Fade>
            </CustomBlock>

          </Col>
          <Col md={12}>
            {/* You can add more content here, such as buttons or images */}
            {/* For now, it's an empty column to create the two-column layout */}
          </Col>
        </Row>
      </Container>
    </CustomBlock>
  );
};

export default CustomBannerInsert;