/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { SiZillow } from 'react-icons/si';
// CUSTOM COMPONENTS
import { Row } from 'react-bootstrap';
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import Image from '../../../../components/Image';
import Overlay from '../../../../components/Overlay';
import FileUploadBlock from '../../../../components/FileUploadBlock';
import FixedActionsBar from '../../../../components/FixedActionsBar';
import TextAreaBlock from '../../../../components/TextAreaBlock';
import Button from '../../../../components/Button';
import SelectBlock from '../../../../components/SelectBlock';
// ASSETS
// HELPERS AND SERVICES
import * as helper from '../../../../helpers/helper';
import * as featuredItemService from '../../../../services/management/featuredItemService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';

const featuredItemModel = {
  id: 0,
  isActive: true,
  title: '',
  description: '',
  amount: '',
  propertyCode: '',
  listingType: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipcode: '',
  imageUrl: '',
  sortOrder: 0,
  primaryActionText: '',
  primaryButtonActionType: '',
  primaryButtonLink: '',
  secondaryButtonText: '',
  secondaryButtonActionType: '',
  secondaryButtonLink: '',
};

const listingTypeOptions = [
  { value: 'Residential', label: 'Residential' },
  { value: 'Commercial', label: 'Commercial' },
  { value: 'ResidentialLot', label: 'Residential Lot' },
  { value: 'CommercialLot', label: 'Commercial Lot' }
];

const FeaturedItemManagementPage = (props) => {
  const { history, showAlert } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { featuredItemId } = useParams();
  const [featuredItem, setFeaturedItem] = useState(featuredItemModel);
  const [file, setFile] = useState([]);
  // PREVIEW
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (featuredItemId) {
      fetchFeaturedItem();
    }
  }, []);

  useEffect(() => {
    formik.setValues(featuredItem);
  }, [featuredItem]);

  const fetchFeaturedItem = () => {
    setIsLoading(true);
    featuredItemService.getFeaturedItemById(featuredItemId).then((res) => {
      setFeaturedItem(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const formik = useFormik({
    initialValues: featuredItem,
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      isActive: Yup.bool(),
      title: Yup.string().nullable().max(150),
      description: Yup.string().nullable().max(300, 'Description must be at most 300 characters'),
      amount: Yup.string().nullable().max(150),
      imageFilePath: Yup.string().max(2000),
      addressLine1: Yup.string().required('Street Address is required').max(250),
      addressLine2: Yup.string().nullable().max(250),
      city: Yup.string().required('City is required').max(250),
      state: Yup.string().required('State is required').max(250),
      zipcode: Yup.string().max(10),
      propertyCode: Yup.string().nullable().max(20),
      listingType: Yup.string().oneOf(listingTypeOptions.map((option) => option.value)).required('Listing type is required'),
      primaryActionText: Yup.string().nullable().max(100),
      primaryButtonActionType: Yup.string().nullable().max(50),
      primaryButtonLink: Yup.string().nullable().max(500),
      secondaryButtonText: Yup.string().nullable().max(100),
      secondaryButtonActionType: Yup.string().nullable().max(50),
      secondaryButtonLink: Yup.string().nullable().max(500),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (featuredItemId) {
        updateFeaturedItem(values);
      } else {
        createFeaturedItem(values);
      }
    },
  });

  const updateFeaturedItem = (model) => {
    setIsLoading(true);
    if (file.length > 0) {
      model = { ...model, image: file[0].file };
    } else {
      model = { ...model, imageUrl: featuredItem.imageFilePath };
    }
    featuredItemService.updateFeaturedItem(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/admin/management/featured-listing');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const createFeaturedItem = (model) => {
    if (file.length === 0) {
      showAlert({ text: 'Please upload an image for the featured item.', state: 'warning' });
      return;
    }
    setIsLoading(true);
    featuredItemService.createFeaturedItem(helper.convertJsonToFormData(
      {
        ...model,
        image: file.length > 0 ? file[0].file : null
      }
    )).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/admin/management/featured-listing');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth className="position-relative">
          {/* PREVIEW */}
          <ContentBlock style={{ position: 'sticky', top: '100px', zIndex: 10000 }}>
            <Section
              hasNoContainer
              className="content-container--card-style--with-shadow card--preview mb-60 position-relative"
            >
              <CustomBlock
                className="position-absolute size-sm white--sbg px-3 py-0 rounded dark--clr fw-500 d-flex align-items-center gap-3 user-select-none"
                onClick={() => setShowPreview(!showPreview)}
                style={{
                  top: '-18px', left: '50%', zIndex: '100', transform: 'translateX(-50%)', border: '2px solid var(--grey-color)', cursor: 'pointer',
                }}
              >
                <span className="">PREVIEW</span>
                <Button
                  text={showPreview ? 'Hide' : 'Show'}
                  className="dark--bg white--clr size-xxxs px-2 py-0 rounded user-select-none"
                />
              </CustomBlock>

              {showPreview && (
                <Row className="w-100 px-0" style={{ rowGap: '3rem' }}>
                  {/* LEFT COLUMN: TEXT AND CAPTION INFO */}
                  <ContentBlock cols={6} className="d-flex flex-column justify-content-center px-0">
                    {formik.values && (
                    <CustomBlock>
                      {/* TITLE */}
                      <h3 className="size-lg primary--clr primary-animated-gradient--clr fw-800 lh-1">
                        {formik.values.title || ''}
                      </h3>

                      {/* ADDRESS */}
                      <CustomBlock className="mt-2">
                        {formik.values.addressLine1 && (
                        <span className="size-sm dark--clr">
                          {formik.values.addressLine1}
                          {formik.values.addressLine2 && `, ${formik.values.addressLine2}`}
                        </span>
                        )}
                        {formik.values.city && (
                        <span className="size-sm dark--clr">
                          {`, ${formik.values.city}`}
                        </span>
                        )}
                        {formik.values.state && (
                        <span className="size-sm dark--clr">
                          {`, ${formik.values.state}`}
                        </span>
                        )}
                        {formik.values.zipcode && (
                        <span className="size-sm dark--clr">
                          {`, ${formik.values.zipcode}`}
                        </span>
                        )}
                      </CustomBlock>

                      {/* AMOUNT AND PROPERTY CODE */}
                      {(formik.values.amount || formik.values.propertyCode) && (
                      <CustomBlock className="d-flex align-items-center flex-wrap gap-2 mt-2">
                        {formik.values.amount && (
                        <p className="size-sm dark--clr lighter-grey--sbg round px-2">
                          <span className="size-xs dark--clr fw-400" style={{ opacity: '70%' }}>$</span>
                          {formik.values.amount}
                        </p>
                        )}
                        {formik.values.propertyCode && (
                        <p className="size-sm dark--clr lighter-grey--sbg round px-2">
                          <span className="size-xs dark--clr fw-400" style={{ opacity: '70%' }}>#</span>
                          <span>{formik.values.propertyCode}</span>
                        </p>
                        )}
                      </CustomBlock>
                      )}

                      {/* DESCRIPTION */}
                      {formik.values.description && (
                        <CustomBlock className="d-flex align-items-center flex-wrap gap-2 mt-3">
                          <p className="size-xs dark--clr pr-3">
                            {formik.values.description.split('\n').map((line, index) => (
                              <>
                                {line}
                                {index < formik.values.description.split('\n').length - 1 && (
                                  <div style={{ height: '8px' }} />
                                )}
                              </>
                            ))}
                          </p>
                        </CustomBlock>
                      )}

                      {/* LISTING ACTIONS */}
                      {(formik.values.primaryButtonLink || formik.values.secondaryButtonLink) && (
                      <CustomBlock className="d-flex align-items-center flex-wrap gap-2 mt-4">
                        {/* Primary Button */}
                        {formik.values.primaryButtonLink && (
                          formik.values.primaryButtonLink.includes('zillow') ? (
                            <button
                              // href="#"
                              className="px-4 py-2 circle text-white d-flex align-items-center gap-2 lighten-bg-hover"
                              style={{ backgroundColor: '#016AFF', borderRadius: '50px' }}
                            >
                              <SiZillow size={18} />
                              {formik.values.primaryActionText || 'Zillow Action Label'}
                            </button>
                          ) : (
                            <Button
                              // to="#"
                              className="px-4 py-2 circle primary--bg text-white d-flex align-items-center gap-2"
                              text={formik.values.primaryActionText || 'Primary Action Label'}
                            />
                          )
                        )}

                        {/* Secondary Button */}
                        {formik.values.secondaryButtonLink && (
                          formik.values.secondaryButtonLink.includes('zillow') ? (
                            <button
                              // to="#"
                              className="px-4 py-2 circle text-white d-flex align-items-center gap-2"
                              style={{ backgroundColor: '#016AFF' }}
                              target="_blank"
                              
                            >
                              <SiZillow size={18} />
                              {formik.values.secondaryButtonText || 'Zillow Action Label'}
                            </button>
                          ) : (
                            <Button
                              // to="#"
                              className="px-4 py-2 circle secondary--bg text-white d-flex align-items-center gap-2"
                              text={formik.values.secondaryButtonText || 'Secondary Action Text'}
                            />
                          )
                        )}
                      </CustomBlock>
                      )}

                    </CustomBlock>
                    )}
                  </ContentBlock>

                  {/* RIGHT COLUMN: IMAGE */}
                  <ContentBlock cols={6} className="d-flex align-items-center justify-content-center px-0">
                    <div style={{ width: '100%' }}>
                      <div className="carousel-wrapper">
                        <div
                          style={{
                            position: 'relative',
                            backgroundImage: `url(${
                              file.length > 0
                                ? URL.createObjectURL(file[0].file)
                                : formik.values.imageFilePath
                                  ? `${process.env.REACT_APP_API_URL}Attachments/${formik.values.imageFilePath}`
                                  : ''
                            })`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            width: '100%',
                            paddingTop: '60%',
                            maxHeight: '400px',
                            borderRadius: '10px',
                          }}
                        />
                      </div>
                    </div>
                  </ContentBlock>
                </Row>
              )}
            </Section>
          </ContentBlock>

          {/* MANAGEMENT */}
          <ContentBlock>
            <FormBlock onSubmit={formik.submitForm}>
              {/* BASIC INFORMATION & LOCATION DETAILS */}
              <Section
                hasNoContainer
                className="content-container--card-style--with-shadow mb-60"
              >
                <ContentBlock>
                  <ContentHeader
                    title={featuredItemId ? 'Featured Item Details' : 'New Featured Item'}
                    headerSize="lg"
                  />
                </ContentBlock>

                <ContentBlock className="mb-15 ml-0">
                  <CheckboxBlock
                    label="Is Item Active?"
                    id="isActive"
                    {...formik.getFieldProps('isActive')}
                    isChecked={formik.values.isActive}
                  />
                </ContentBlock>

                <ContentBlock>
                  <ContentHeader
                    title="Basic Information"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <Section isFullWidth className="pl-0 pr-0">
                  <Section isFullWidth className="pl-0 pr-0 mb-10">
                    <ContentBlock cols={3}>
                      <SelectBlock
                        isRequired
                        label="Listing Type"
                        options={listingTypeOptions}
                        value={listingTypeOptions.find((option) => option.value === formik.values.listingType)}
                        errorMessage={formik.errors.listingType}
                        inputState={`${helper.getInputClasses(formik, 'listingType')}`}
                        onChange={(opt) => {
                          formik.setFieldValue('listingType', opt.value);
                        }}
                      />
                    </ContentBlock>
                  </Section>

                  <ContentBlock cols={3}>
                    <InputBlock
                      isRequired
                      label="Title"
                      placeholder="River Pines Plantation"
                      errorMessage={formik.errors.title}
                      inputState={`${helper.getInputClasses(formik, 'title')}`}
                      {...formik.getFieldProps('title')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={3}>
                    <InputBlock
                      label="Listing Amount"
                      placeholder="120,000"
                      errorMessage={formik.errors.amount}
                      inputState={`${helper.getInputClasses(formik, 'amount')}`}
                      {...formik.getFieldProps('amount')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={3}>
                    <InputBlock
                      label="MLS/Property Code"
                      placeholder="2023001122"
                      errorMessage={formik.errors.propertyCode}
                      inputState={`${helper.getInputClasses(formik, 'propertyCode')}`}
                      {...formik.getFieldProps('propertyCode')}
                    />
                  </ContentBlock>
                </Section>

                <Section isFullWidth className="pl-0 pr-0">
                  <ContentBlock cols={9}>
                    <TextAreaBlock
                      label="Description"
                      labelColor="dark--clr"
                      placeholder="e.g. Beautiful 3-bed, 2-bath home with modern finishes and spacious backyard."
                      errorMessage={formik.errors.description}
                      inputState={`${helper.getInputClasses(formik, 'description')}`}
                      {...formik.getFieldProps('description')}
                    />
                  </ContentBlock>
                </Section>
              </Section>

              <Section
                hasNoContainer
                className="content-container--card-style--with-shadow mb-60"
              >
                {/* PRIMARY BUTTON OPTIONS */}
                <ContentBlock>
                  <ContentHeader
                    title="Primary Action"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <Section isFullWidth className="pl-0 pr-0">
                  <ContentBlock cols={5}>
                    <InputBlock
                      label="Primary Action Label"
                      placeholder="e.g. Learn More"
                      errorMessage={formik.errors.primaryActionText}
                      inputState={`${helper.getInputClasses(formik, 'primaryActionText')}`}
                      {...formik.getFieldProps('primaryActionText')}
                    />
                  </ContentBlock>

                  {/* <ContentBlock cols={4}>
                  <InputBlock
                    label="Primary Button Action Type"
                    placeholder="e.g. Navigate to URL or Open Modal"
                    errorMessage={formik.errors.primaryButtonActionType}
                    inputState={`${helper.getInputClasses(formik, 'primaryButtonActionType')}`}
                    {...formik.getFieldProps('primaryButtonActionType')}
                  />
                </ContentBlock> */}

                  <ContentBlock cols={5}>
                    <InputBlock
                      label="Primary Action Link"
                      placeholder="e.g. https://example.com or /contact-us"
                      errorMessage={formik.errors.primaryButtonLink}
                      inputState={`${helper.getInputClasses(formik, 'primaryButtonLink')}`}
                      {...formik.getFieldProps('primaryButtonLink')}
                    />
                  </ContentBlock>
                </Section>

                {/* SECONDARY BUTTON OPTIONS */}
                <ContentBlock>
                  <ContentHeader
                    title="Secondary Action"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr py-15"
                  />
                </ContentBlock>

                <Section isFullWidth className="pl-0 pr-0">
                  <ContentBlock cols={5}>
                    <InputBlock
                      label="Secondary Action Label"
                      placeholder="e.g. View Details"
                      errorMessage={formik.errors.secondaryButtonText}
                      inputState={`${helper.getInputClasses(formik, 'secondaryButtonText')}`}
                      {...formik.getFieldProps('secondaryButtonText')}
                    />
                  </ContentBlock>

                  {/* <ContentBlock cols={4}>
                  <InputBlock
                    label="Secondary Button Action Type"
                    placeholder="e.g. Navigate to URL or Open Modal"
                    errorMessage={formik.errors.secondaryButtonActionType}
                    inputState={`${helper.getInputClasses(formik, 'secondaryButtonActionType')}`}
                    {...formik.getFieldProps('secondaryButtonActionType')}
                  />
                </ContentBlock> */}

                  <ContentBlock cols={5}>
                    <InputBlock
                      label="Secondary Action Link"
                      placeholder="e.g. https://example.com or /learn-more"
                      errorMessage={formik.errors.secondaryButtonLink}
                      inputState={`${helper.getInputClasses(formik, 'secondaryButtonLink')}`}
                      {...formik.getFieldProps('secondaryButtonLink')}
                    />
                  </ContentBlock>
                </Section>
              </Section>

              {/* LOCATION */}
              <Section hasNoContainer className="content-container--card-style--with-shadow mt-30 mb-60">
                <ContentBlock className="mb-30">
                  <ContentHeader
                    title="Location"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr"
                  />
                </ContentBlock>
                
                <Section isFullWidth className="pl-0 pr-0">
                  <ContentBlock cols={6}>
                    <InputBlock
                      isRequired
                      label="Street Address"
                      placeholder="123 Main St"
                      errorMessage={formik.errors.addressLine1}
                      inputState={`${helper.getInputClasses(formik, 'addressLine1')}`}
                      {...formik.getFieldProps('addressLine1')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={3}>
                    <InputBlock
                      label="Apt/Unit/Suit"
                      placeholder="Apt. B"
                      errorMessage={formik.errors.addressLine2}
                      inputState={`${helper.getInputClasses(formik, 'addressLine2')}`}
                      {...formik.getFieldProps('addressLine2')}
                    />
                  </ContentBlock>
                </Section>

                <Section isFullWidth className="pl-0 pr-0">
                  <ContentBlock cols={3}>
                    <InputBlock
                      isRequired
                      label="City"
                      placeholder="Baton Rouge"
                      errorMessage={formik.errors.city}
                      inputState={`${helper.getInputClasses(formik, 'city')}`}
                      {...formik.getFieldProps('city')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={3}>
                    <InputBlock
                      isRequired
                      label="State"
                      placeholder="LA"
                      errorMessage={formik.errors.state}
                      inputState={`${helper.getInputClasses(formik, 'state')}`}
                      {...formik.getFieldProps('state')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={3}>
                    <InputBlock
                      label="Zip/Post Code"
                      placeholder="12345"
                      errorMessage={formik.errors.zipcode}
                      inputState={`${helper.getInputClasses(formik, 'zipcode')}`}
                      {...formik.getFieldProps('zipcode')}
                    />
                  </ContentBlock>
                </Section>
              </Section>

              {/* FEATURED ITEM IMAGE */}
              <Section
                hasNoContainer
                className="content-container--card-style--with-shadow mt-30 mb-80"
              >
                <ContentBlock>
                  <ContentHeader
                    title="Featured Listing Image"
                    subtitle="Upload an image for this featured item."
                    headerSize="md"
                    subtitleSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                    containerClassName="mb-30"
                  />
                  <ContentBlock cols={6}>
                    {!featuredItemId
                      ? (
                        <ContentBlock className="mt-15">
                          <FileUploadBlock onupdatefiles={setFile} />
                        </ContentBlock>
                      )
                      : (
                        <>
                          <CustomBlock className="flex-center pt-15 pb-30">
                            <Image
                              source={`${process.env.REACT_APP_API_URL}Attachments/${formik.values.imageFilePath}`}
                              className="featuredItem-image pl-10 pr-10"
                            />
                          </CustomBlock>
                          <FileUploadBlock onupdatefiles={setFile} labelIdle="Replace image" />
                        </>
                      )}
                  </ContentBlock>
                </ContentBlock>
              </Section>

              {/* PAGE ACTIONS */}
              <FixedActionsBar
                primaryActionText={featuredItemId > 0 ? 'Save Changes' : 'Create Featured Item'}
                primaryActionColor="success--bg"
                primaryActionOnClick={formik.handleSubmit}
                secondaryActionText="Cancel"
                secondaryActionTo="/admin/management/featured-listing"
                optionalActionText={featuredItemId > 0 ? 'Return to Listing' : ''}
                optionalActionTo="/admin/management/featured-listing"
              />
            </FormBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...auth.actions,
    ...alert.actions
  }
)(FeaturedItemManagementPage);