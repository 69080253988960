/* eslint-disable no-nested-ternary */
// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// COMPONENTS
import Select from 'react-select';
import Async from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
// ICONS
import { AiOutlineLoading } from 'react-icons/ai';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const SelectBlock = (props) => {
  const {
    containerClassName,
    label,
    labelColor,
    inputLabel,
    inputState,
    errorMessage,
    successMessage,
    warningMessage,
    className,
    backgroundColor,
    placeholder,
    isAsync,
    isCreatable,
    isLabelLoading,
    isMulti,
    isRequired,
    isDisabled,
    hasBorder,
  } = props;

  const errorMessageSuffix = 'is required';
  const inputStates = {
    error: 'error',
    warning: 'warning',
    success: 'success',
    disabled: 'disabled'
  };

  return (
    <CustomBlock className={`input-block-container ${containerClassName}`}>
      {
        inputLabel
        && (
          <span className={`static-floating-label ${inputState}`}>
            {inputLabel}
            {isRequired && <span className="danger--clr fw-600">*</span>}
            {
              isLabelLoading
              && (
                <AiOutlineLoading
                  tabIndex="-1"
                  className="loading-indicator rotating success--clr ml-5"
                  size={16}
                  style={{ marginTop: -5 }}
                />
              )
            }
          </span>
        )
      }
      {
        label
        && (
          <span className={`static-label ${labelColor}`} style={{ display: 'inline-block' }}>
            {label}
            {isRequired && <span className="danger--clr fw-600">*</span>}
            {
              isLabelLoading
              && (
                <AiOutlineLoading
                  tabIndex="-1"
                  className="loading-indicator rotating success--clr ml-10"
                  size={18}
                />
              )
            }
          </span>
        )
      }

      {
        isAsync
          ? (
            <Async
              {...props}
              isMulti={isMulti}
              placeholder={placeholder || (isMulti ? 'Select options' : 'Select option')}
              classNamePrefix={`${className} ${backgroundColor} ${inputState && inputStates[inputState]} ${hasBorder ? 'hasBorder' : ''} ${!inputLabel || !label ? 'padded' : ''}`}
              isDisabled={!!(isDisabled || inputState === 'disabled')}
              // disabled={inputState === 'disabled'}
              className="react-select"
            />
          )
          : (
            isCreatable
              ? (
                <CreatableSelect
                  {...props}
                  isMulti={isMulti}
                  placeholder={placeholder || (isMulti ? 'Select options' : 'Select option')}
                  classNamePrefix={`${className || ''} ${backgroundColor} ${inputState && inputStates[inputState]} ${hasBorder ? 'hasBorder' : ''} ${!inputLabel || !label ? 'padded' : ''}`}
                  isDisabled={!!(isDisabled || inputState === 'disabled')}
                  // disabled={inputState === 'disabled'}
                  className="react-select"
                />
              )
              : (
                <Select
                  {...props}
                  isMulti={isMulti}
                  placeholder={placeholder || (isMulti ? 'Select options' : 'Select option')}
                  classNamePrefix={`${className || ''} ${backgroundColor} ${inputState && inputStates[inputState]} ${hasBorder ? 'hasBorder' : ''} ${!inputLabel || !label ? 'padded' : ''}`}
                  isDisabled={!!(isDisabled || inputState === 'disabled')}
                  // disabled={inputState === 'disabled'}
                  className="react-select"
                />
              )
          )
      }

      {
        inputState === 'error'
        && (
          <span className={`input-state-text error-message ${!inputLabel || label ? 'unpadded' : ''}`}>
            {errorMessage || (inputLabel || label ? (inputLabel || `${label} ${errorMessageSuffix}`) : 'Error message undefined')}
          </span>
        )
      }
      {
        inputState === 'warning'
        && (
          <span className={`input-state-text warning-message ${!inputLabel || label ? 'unpadded' : ''}`}>
            {warningMessage}
          </span>
        )
      }
      {
        inputState === 'success'
        && (
          <span className={`input-state-text success-message ${!inputLabel || label ? 'unpadded' : ''}`}>
            {successMessage}
          </span>
        )
      }
    </CustomBlock>
  );
};

SelectBlock.propTypes = {
  // CONTAINER PROPS
  containerClassName: PropTypes.string,

  // LABEL PROPS
  label: PropTypes.string,
  labelColor: PropTypes.string,
  inputLabel: PropTypes.string,

  // STATE PROPS
  inputState: PropTypes.string,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  warningMessage: PropTypes.string,

  // INPUT PROPS
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  placeholder: PropTypes.string,

  // BOOLEAN PROPS
  isAsync: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isLabelLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasBorder: PropTypes.bool,
};

SelectBlock.defaultProps = {
  // CONTAINER PROPS
  containerClassName: '',

  // LABEL PROPS
  label: '',
  labelColor: '',
  inputLabel: '',

  // STATE PROPS
  inputState: '',
  errorMessage: '',
  successMessage: '',
  warningMessage: '',

  // INPUT PROPS
  className: '',
  backgroundColor: 'light--sbg',
  placeholder: '',

  // BOOLEAN PROPS
  isAsync: false,
  isCreatable: false,
  isLabelLoading: false,
  isMulti: false,
  isRequired: false,
  isDisabled: false,
  hasBorder: false,
};

export default SelectBlock;