/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/no-unknown-property */
// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FaPhoneAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import {
  LuCopy, LuSmartphone, LuMail, LuMessageSquare
} from 'react-icons/lu';
import { TbMapPin } from 'react-icons/tb';
// COMPONENTS
import {
  Container, Row, Col, OverlayTrigger, Popover
} from 'react-bootstrap';
import SocialIconSet from './SocialIcons/SocialIconSet';
import Button from './Button';
import CustomBlock from './CustomBlock';
import Image from './Image';
import InputBlock from './InputBlock';
import TextBlock from './TextBlock';
// ASSETS
import AppStoreBadge from '../assets/img/app-store-badge.svg';
import GooglePlayBadge from '../assets/img/google-play-badge.svg';
import MLSRealtorLogo from '../assets/client/mls-realtor-logo.svg';

const FooterBlock = (props) => {
  const {
    auth,
    history,
    to,
    phoneNumber,
    emailAddress,
    imageSource,
    backgroundColor,
    bottomFooterBackgroundColor,
    subheaderColor,
    subheaderClassName,
    companyName,
    copyrightTextColor,
    footerHeaderTextColor,
    footerItems,
    iconSize,
    iconAppearance,
    socialIconColor,
    socialIconBackgroundColor,
    appStoreLink,
    googlePlayLink,
    facebookLink,
    instagramLink,
    twitterLink,
    linkedInLink,
    youtubeLink,
    tiktokLink,
    // primaryActionOnClick,
    // secondaryActionOnClick,
    hasSocialMediaIconColor,
    hasEmailSignUp,
    hasContactBlock,
    cmsInfo,
    companyLogos,
    isMembersLoginModalOpen,
    setIsMembersLoginModalOpen
  } = props;
  const popoverBtnIconSize = 20;
  const popoverBtnIconStrokeWidth = 2.4;
  const [isPhonePopoverOpen, setIsPhonePopoverOpen] = useState(false);
  const [isEmailPopoverOpen, setIsEmailPopoverOpen] = useState(false);

  const iconAppearanceOptions = {
    square: 'square',
    round: 'round',
    circle: 'circle',
  };

  const openInNewTab = (link) => {
    window.open(link, '_blank');
  };

  const navigateToPage = (page) => {
    history.push(page);
  };

  const navigateToLink = (link) => {
    window.open(link, '_blank');
  };

  const sendEmail = (email) => {
    window.open(`mailto:${email}`, '_self');
  };

  const callPhoneNumber = (tel) => {
    window.open(`tel:${tel}`, '_self');
  };

  const handlePhonePopoverToggle = () => {
    setIsPhonePopoverOpen(!isPhonePopoverOpen);
  };

  const handleEmailPopoverToggle = () => {
    setIsEmailPopoverOpen(!isEmailPopoverOpen);
  };

  const phonePopover = (
    <Popover id="popover-phone" className="popover-body secondary--sbg secondary--clr p-2">
      <CustomBlock
        className="popover-btn secondary-lighter--hover-bg"
        onClick={() => {
          navigator.clipboard.writeText(phoneNumber);
          setIsPhonePopoverOpen(false);
        }}
      >
        <LuCopy className="white--clr opacity-90" size={popoverBtnIconSize} strokeWidth={popoverBtnIconStrokeWidth} />
        <p className="size-xs fw-400 white--clr">
          Copy
        </p>
      </CustomBlock>

      <hr className="white--clr my-1 opacity-15" style={{ height: '2px' }} />

      <CustomBlock
        className="popover-btn secondary-lighter--hover-bg"
        onClick={() => {
          callPhoneNumber(phoneNumber);
          setIsPhonePopoverOpen(false);
        }}
      >
        <LuSmartphone className="white--clr opacity-90" size={popoverBtnIconSize} strokeWidth={popoverBtnIconStrokeWidth} />
        <p className="size-xs fw-400 white--clr">
          {/* {`Call ${cmsInfo.companyName}`} */}
          Call Us
        </p>
      </CustomBlock>
    </Popover>
  );

  const emailPopover = (
    <Popover id="popover-email" className="popover-body primary--sbg primary--clr p-2">
      <CustomBlock
        className="popover-btn primary-lightest--hover-bg"
        onClick={() => {
          navigator.clipboard.writeText(emailAddress);
          setIsEmailPopoverOpen(false);
        }}
      >
        <LuCopy className="white--clr" size={popoverBtnIconSize} strokeWidth={popoverBtnIconStrokeWidth} />
        <p className="size-xs fw-400 white--clr">
          Copy
        </p>
      </CustomBlock>

      <hr className="white--clr my-1 opacity-15" style={{ height: '2px' }} />

      <CustomBlock
        className="popover-btn primary-lightest--hover-bg"
        onClick={() => {
          sendEmail(emailAddress);
          setIsEmailPopoverOpen(false);
        }}
      >
        <LuMail className="white--clr" size={popoverBtnIconSize} strokeWidth={popoverBtnIconStrokeWidth} />
        <p className="size-xs fw-400 white--clr">
          {/* {`Email ${cmsInfo.companyName}`} */}
          Send an Email
        </p>
      </CustomBlock>

      <hr className="white--clr my-1 opacity-15" style={{ height: '2px' }} />

      <CustomBlock
        className="popover-btn primary-lightest--hover-bg"
        onClick={() => {
          const contactPageURL = cmsInfo.menuItems.find((item) => item.name === 'Contact').customLink;
          history.push(contactPageURL);
          setIsEmailPopoverOpen(false);
        }}
      >
        <LuMessageSquare className="white--clr" size={popoverBtnIconSize} strokeWidth={popoverBtnIconStrokeWidth} />
        <p className="size-xs fw-400 white--clr">
          Visit Contact Page
        </p>
      </CustomBlock>
    </Popover>
  );

  return (
    <CustomBlock className="footer-block footer-block-bg">
      {/* ROW - MAIN */}
      <Container className="main-footer">
        <Row className="d-flex justify-content-between" style={{ rowGap: '40px', columnGap: '20px' }}>
          {/* COL LEFT - LOGO */}
          <Col md={12} lg={3} xl={3} className="left-block mb-4 mb-lg-0 d-flex justify-content-center align-self-center">
            <CustomBlock className="d-flex flex-column align-items-center gap-3">
              <Image
                to={to}
                source={companyLogos.alt}
                style={{ width: '280px' }}
                className="user-select-none"
              />
              <span
                className="golden-button fw-600 size-xxs fw-700 px-3 rounded cursor-default"
                style={{ boxShadow: 'none', cursor: 'default' }}
                // style={{
                //   boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(110, 80, 20, 0.4), inset 0 -2px 5px 1px #8b4208, inset 0 -1px 1px 3px #fae385',
                //   backgroundImage: 'linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07)',
                //   backgroundSize: '1000% 1000%',
                //   backgroundPosition: 'center',
                //   border: '1px solid #a55d07',
                //   color: '#783205',
                // }}
              >
                <span className="user-select-none">MLS# </span>
                995713490
              </span>
            </CustomBlock>
          </Col>

          {/* COL MIDDLE - QUICK LINKS */}
          <Col md={12} lg={6} xl={3} className="center-block mb-4 mb-lg-0" style={{ minWidth: '400px' }}>
            <TextBlock
              isUppercase
              isPadded
              type="subheader"
              text="Quick Links"
              color={footerHeaderTextColor}
              className="fw-700 fit-content"
            />

            <CustomBlock className="container links m-0 p-0">
              <CustomBlock className="row">
                {footerItems.map((item, index) => (
                  <CustomBlock className="col-6 mb-2 p-0 p-1">
                    <NavLink
                      to={item.to}
                      onClick={(e) => {
                        if (item.to.includes('https') || item.to.includes('http')) {
                          e.preventDefault();
                          navigateToLink(item.to);
                        }
                      }}
                      className="d-block text-white secondary--hover-underline fit-content text-nowrap"
                      style={{ wordWrap: 'break-word', lineHeight: '1.5' }}
                    >
                      {item.label}
                    </NavLink>
                  </CustomBlock>
                ))}
              </CustomBlock>
            </CustomBlock>
            
          </Col>

          {/* COL RIGHT - CONTACT US */}
          <Col md={12} lg={6} xl={3} className="right-block ">
            {hasEmailSignUp && (
              <CustomBlock className="mb-4">
                <TextBlock
                  isUppercase
                  type="subheader"
                  text="Get the latest deals"
                  color={footerHeaderTextColor}
                  className="mb-3"
                />

                <InputBlock
                  placeholder="Enter your email"
                  inputRightButtonText="Sign Up"
                  inputRightButtonBackgroundColor={`lighter-blue--sbg ${iconAppearance === 'circle' ? 'circle' : ''}`}
                  inputRightButtonTextColor="info--clr"
                  backgroundColor="white--sbg"
                  className={`${iconAppearance === 'circle' ? 'circle' : ''}`}
                />
              </CustomBlock>
            )}

            {hasContactBlock && (
              <CustomBlock className="contact-block mb-4 fit-content">
                <TextBlock
                  isUppercase
                  type="subheader"
                  text="Contact Us"
                  color={footerHeaderTextColor}
                  className="fw-700 mb-3 fit-content"
                />
                <CustomBlock className="d-flex flex-column gap-20">
                  {/* EMAIL POPOVER */}
                  {emailAddress && (
                    <OverlayTrigger
                      trigger="click"
                      placement="top"
                      overlay={emailPopover}
                      show={isEmailPopoverOpen}
                      onToggle={handleEmailPopoverToggle}
                      rootClose
                    >
                      <CustomBlock
                        className="flex-start footer-company-details"
                        onTouchStart={handleEmailPopoverToggle}
                      >
                        <Button
                          text={emailAddress}
                          className="primary--bg"
                          size="md"
                          iconLeft={<MdEmail className="light--clr" size="20" />}
                        />
                      </CustomBlock>
                    </OverlayTrigger>
                  )}
                  
                  {/* PHONE POPOVER */}
                  {phoneNumber && (
                    <OverlayTrigger
                      trigger="click"
                      placement="top"
                      overlay={phonePopover}
                      show={isPhonePopoverOpen}
                      onToggle={handlePhonePopoverToggle}
                      rootClose
                    >
                      <CustomBlock
                        className="flex-start footer-company-details"
                        onTouchStart={handlePhonePopoverToggle}
                      >
                        <Button
                          text={phoneNumber}
                          className="secondary--bg"
                          size="md"
                          iconLeft={<FaPhoneAlt className="light--clr" size="18" />}
                        />
                      </CustomBlock>
                    </OverlayTrigger>
                  )}
                </CustomBlock>
              </CustomBlock>
            )}

            {(appStoreLink || googlePlayLink) && (
              <CustomBlock className="app-download-block">
                <TextBlock
                  isPadded
                  isUppercase
                  type="subheader"
                  text="Download Our App"
                  color={subheaderColor}
                  className={`${subheaderClassName} mb-3`}
                />

                <CustomBlock className="app-download-badges">
                  {appStoreLink && (
                  <Image
                    source={AppStoreBadge}
                    onClick={() => navigateToLink(appStoreLink)}
                    className="mb-2"
                    style={{ width: '150px' }}
                  />
                  )}

                  {googlePlayLink && (
                  <Image
                    source={GooglePlayBadge}
                    onClick={() => navigateToLink(googlePlayLink)}
                    style={{ width: '150px' }}
                  />
                  )}
                </CustomBlock>
              </CustomBlock>
            )}
          </Col>
        </Row>
      </Container>

      {/* ROW - SOCIAL ICONS */}
      <CustomBlock className="social-media-block container mt-4">
        <CustomBlock className="social-icons">
          <SocialIconSet
            auth={auth}
            cmsInfo={cmsInfo}
            history={history}
            location="footer"
            isMembersLoginModalOpen={isMembersLoginModalOpen}
            setIsMembersLoginModalOpen={setIsMembersLoginModalOpen}
          />
        </CustomBlock>
      </CustomBlock>

      <CustomBlock className="d-flex flex-column align-items-center">
        <CustomBlock className="d-flex align-items-center justify-content-center flex-wrap" style={{ rowGap: '10px', columnGap: '20px' }}>
          <CustomBlock>
            <img src={MLSRealtorLogo} alt="MLS Realtor Logo" style={{ height: '2.5rem' }} />
          </CustomBlock>

          <CustomBlock className="d-flex flex-column size-xxxs white--clr fw-400">
            <p className="d-flex align-items-center gap-1 lh-0 secondary--clr size-xxs" title="Crossroads Realty Location">
              <TbMapPin className="lh-0 secondary--clr" />
              <span className="size-xxxs">Watson, LA</span>
            </p>
            <p className="lh-0 secondary--clr">Each Office Independently Owned and Operated</p>
            <p className="lh-0 secondary--clr">REALTOR licensed by the State of Louisiana.</p>
          </CustomBlock>
        </CustomBlock>

        {/* ROW - COPYRIGHT */}
        <CustomBlock className="d-flex align-items-center justify-content-center flex-wrap gap-1 my-3 user-select-none">
          <CustomBlock className="d-flex align-items-center justify-content-center flex-wrap gap-1">
            <p className="size-xxxs white--clr">{`Copyright © ${new Date().getFullYear()} ${companyName}.`}</p>
            <p className="size-xxxs white--clr">All Rights Reserved.</p>
          </CustomBlock>
          <button
            className="size-xxxs white--clr fw-500 secondary--hover-underline"
            onClick={() => navigateToLink('https://primtek.net/')}
          >
            Developed by Primtek.
          </button>
        </CustomBlock>
      </CustomBlock>

    </CustomBlock>
  );
};

FooterBlock.propTypes = {
  // MAIN PROPS
  to: PropTypes.string,
  backgroundColor: PropTypes.string,
  bottomFooterBackgroundColor: PropTypes.string,
  imageSource: PropTypes.string,

  // CONTACT PROPS
  phoneNumber: PropTypes.string,
  emailAddress: PropTypes.string,

  // OPTIONAL PROPS

  // SUBHEADER PROPS
  subheaderColor: PropTypes.string,
  subheaderClassName: PropTypes.string,

  // APP STORE PROPS
  appStoreLink: PropTypes.string,
  googlePlayLink: PropTypes.string,

  // SOCIAL MEDIA PROPS
  iconSize: PropTypes.number,
  iconAppearance: PropTypes.string.isRequired,
  socialIconBackgroundColor: PropTypes.string,
  socialIconColor: PropTypes.string,
  facebookLink: PropTypes.string,
  instagramLink: PropTypes.string,
  twitterLink: PropTypes.string,
  linkedInLink: PropTypes.string,
  youtubeLink: PropTypes.string,
  tiktokLink: PropTypes.string,

  // FOOTER PROPS
  footerHeaderTextColor: PropTypes.string.isRequired,

  // BOTTOM FOOTER PROPS
  companyName: PropTypes.string.isRequired,
  copyrightTextColor: PropTypes.string,

  // FUNCTION PROPS
  // primaryActionOnClick: PropTypes.func,
  // secondaryActionOnClick: PropTypes.func,

  // BOOLEAN PROPS
  hasSocialMediaIconColor: PropTypes.bool,
  hasEmailSignUp: PropTypes.bool,
  hasContactBlock: PropTypes.bool,

  footerItems: PropTypes.array,
};

FooterBlock.defaultProps = {
  // MAIN PROPS
  to: '#',
  backgroundColor: 'white--sbg',
  bottomFooterBackgroundColor: 'lightest-grey--sbg',
  imageSource: '',

  // CONTACT PROPS
  phoneNumber: '',
  emailAddress: '',

  // OPTIONAL PROPS

  // SUBHEADER PROPS
  subheaderColor: 'secondary--clr',
  subheaderClassName: '',

  // APP STORE PROPS
  appStoreLink: '',
  googlePlayLink: '',

  // SOCIAL MEDIA PROPS
  iconSize: 27,
  socialIconColor: 'white--clr',
  socialIconBackgroundColor: 'secondary--sbg',
  facebookLink: '',
  instagramLink: '',
  twitterLink: '',
  linkedInLink: '',
  youtubeLink: '',
  tiktokLink: '',

  // BOTTOM FOOTER PROPS
  copyrightTextColor: 'primary--clr',

  // FUNCTION PROPS
  // primaryActionOnClick: null,
  // secondaryActionOnClick: null,

  // BOOLEAN PROPS
  hasSocialMediaIconColor: false,
  hasEmailSignUp: false,
  hasContactBlock: true,

  footerItems: [],
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, null)(FooterBlock);