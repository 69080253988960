/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-max-props-per-line */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
import { SwatchesPicker } from 'react-color';
import {
  DragDropContext,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';
// ICONS
import { MdOutlinePlaylistAdd } from 'react-icons/md';
import { FaSquare } from 'react-icons/fa';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import TextAreaBlock from '../../../../components/TextAreaBlock';
import SelectBlock from '../../../../components/SelectBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import Button from '../../../../components/Button';
import Overlay from '../../../../components/Overlay';
import ModalBlock from '../../../../components/ModalBlock';
import Image from '../../../../components/Image';
import FileUploadBlock from '../../../../components/FileUploadBlock';
import FixedActionsBar from '../../../../components/FixedActionsBar';
// ASSETS
// SERVICES AND HELPERS
import * as helper from '../../../../helpers/helper';
import * as pageService from '../../../../services/cms/pageService';
import * as contentSectionService from '../../../../services/cms/contentSectionService';
import PageValidator from '../../../../helpers/validators/cms/page/pageValidator';
// REDUX
import * as auth from '../../../../redux/authRedux';
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModalRedux from '../../../../redux/confirmModalRedux';

const intialPageModel = {
  id: 0,
  name: '',
  url: '',
  isBannerVisible: true,
  bannerImageFilePath: '',
  bannerColor: '#000000',
  bannerTitle: '',
  title: '',
  keywords: '',
  description: '',
  isActive: true,
  bannerImage: null,
  contentSections: []
};

const typeSelectionOptions = [
  {
    label: 'Banner',
    value: 'BANNER'
  },
  {
    label: 'Content Section',
    value: 'CONTENTSECTION'
  }
];

const PageManagementPage = (props) => {
  const { showAlert, history, showConfirmModal, hideConfirmModal } = props;
  const { pageId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [file, setFile] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [contentBannerTypeOption, setContentBannerTypeOption] = useState([]);
  const [selectedContentBannerTypeOption, setSelectedContentBannerTypeOption] = useState(null);
  const [pageModel, setPageModel] = useState(intialPageModel);
  const [sortList, setSortList] = useState([]);
  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    loadOptionsAndPopulateForm();
    setContentBannerTypeOption([]);
    setSelectedContentBannerTypeOption([]);
  }, [pageId]);

  useEffect(() => {
    setSelectedContentBannerTypeOption(null);
    setContentBannerTypeOption([]);
    if (selectedOption) {
      getOptions(selectedOption.value);
    }
  }, [selectedOption]);

  useEffect(() => {
    setSortList(pageModel.contentSections.sort((a, b) => a - b));
  }, [pageModel.contentSections]);

  const formik = useFormik({
    initialValues: pageModel,
    validationSchema: PageValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (pageId) {
        updatePage(values);
      } else {
        createPage(values);
      }
    },
  });

  const loadOptionsAndPopulateForm = async () => {
    if (pageId > 0) {
      getPage();
    }
  };

  const getOptions = (type) => {
    setIsLoading(true);

    contentSectionService.getContentSectionOptions(pageId, type).then((res) => {
      setContentBannerTypeOption(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getPage = async () => {
    setIsLoading(true);

    pageService.getPageById(pageId).then((res) => {
      setPageModel(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const createPage = (model) => {
    setIsLoading(true);

    pageService.createPage(helper.convertJsonToFormData(
      {
        ...model,
        bannerImage: file.length > 0 ? file[0].file : null
      }
    )).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push(`/cms/management/page/edit/${res.id}`);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const updatePage = (model) => {
    setIsLoading(true);

    if (file.length > 0) {
      model = { ...model, bannerImage: file[0].file };
    }
    pageService.updatePage(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/cms/management/pages');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const hideContentSectionModal = () => {
    setSelectedOption(null);
    setSelectedContentBannerTypeOption(null);
    setContentBannerTypeOption([]);

    setIsModalVisible(false);
  };

  const addContentSection = () => {
    if (selectedContentBannerTypeOption && selectedOption) {
      setIsLoading(true);

      pageService.assignContentSectionToPage(pageId, selectedContentBannerTypeOption.value, selectedOption.value).then((res) => {
        getPage();
        showAlert({ text: res.message, state: 'success' });
        hideContentSectionModal();
      }).catch((ex) => {
        showAlert({ text: ex.message, state: 'warning' });
      }).finally(() => setIsLoading(false));
    } else {
      showAlert({ text: 'Please select both type & component', state: 'warning' });
    }
  };

  const deletePageContent = (id) => {
    setIsLoading(true);

    pageService.unassignContentSection(id).then((res) => {
      getPage();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  const sortPageContents = () => {
    setIsLoading(true);

    pageService.sortPageContentSections(sortList).then((res) => {
      getPage();
      showAlert({ text: res.message, state: 'success' });
      setIsSortModalOpen(false);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const tempArr = helper.reOrderDnd(
      sortList,
      result.source.index,
      result.destination.index
    );

    setSortList(tempArr);
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: false
  };

  const options = {
    selectableRows: 'none',
    download: false,
    print: false,
  };

  const deleteBannerImage = () => {
    pageService.deleteBannerImage(pageId).then((res) => {
      getPage();
      showAlert({ text: res.message, state: 'success' });
      hideConfirmModal();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="info--bg mr-5"
              size="xxs"
              onClick={() => {
                const model = pageModel.contentSections[dataIndex];
                history.push(`/cms/management/${(model.bannerId > 0) ? 'banner' : 'content-section'}/edit/${(model.bannerId > 0) ? model.bannerId : model.contentSectionId}`);
              }}
            />

            <Button
              text="Remove"
              className="danger--bg"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Remove Page Content',
                  text: 'Are you sure you want to remove this content?',
                  rightBtnText: 'Confirm',
                  btnAction: () => {
                    deletePageContent(pageModel.contentSections[dataIndex].id);
                  }
                });
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: columnOptions,
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => (<p>{helper.mapContentSectionName(pageModel.contentSections[dataIndex].type)}</p>)
      },
    },
  ];

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <FormBlock onSubmit={formik.submitForm}>
              <CustomBlock className="content-container--card-style--with-shadow mb-60">
                {/* BASIC INFORMATION */}
                <Section hasNoContainer>
                  <ContentBlock>
                    <ContentHeader
                      title={pageId ? 'Page Details' : 'New Page'}
                      headerSize="lg"
                    />
                  </ContentBlock>

                  <ContentBlock>
                    <ContentHeader
                      title="Basic Information"
                      headerSize="md"
                      className="alt-font fw-600 secondary--clr pb-15"
                    />
                  </ContentBlock>

                  <ContentBlock cols={4}>
                    <InputBlock
                      label="Page Name"
                      placeholder="e.g. Services Page"
                      isRequired
                      errorMessage={formik.errors.name}
                      inputState={`${helper.getInputClasses(formik, 'name')}`}
                      {...formik.getFieldProps('name')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={4}>
                    <InputBlock
                      label="Page Banner Title"
                      placeholder="e.g. Our Services"
                      errorMessage={formik.errors.bannerTitle}
                      inputState={`${helper.getInputClasses(formik, 'bannerTitle')}`}
                      {...formik.getFieldProps('bannerTitle')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={12}>
                    <InputBlock
                      label="URL"
                      placeholder="Begin every URL with a / e.g. /page-name"
                      isRequired
                      errorMessage={formik.errors.url}
                      inputState={`${helper.getInputClasses(formik, 'url')}`}
                      {...formik.getFieldProps('url')}
                    />
                  </ContentBlock>
                  <ContentBlock cols={3} className="mt-15">
                    <CheckboxBlock
                      label="Is Page Active?"
                      id="isActive"
                      {...formik.getFieldProps('isActive')}
                      isChecked={formik.values.isActive}
                    />
                  </ContentBlock>

                  <ContentBlock cols={3} className="mt-15">
                    <CheckboxBlock
                      label="Is Banner Visible?"
                      id="isBannerVisible"
                      {...formik.getFieldProps('isBannerVisible')}
                      isChecked={formik.values.isBannerVisible}
                    />
                  </ContentBlock>
                </Section>

                {/* PAGE BANNER */}
                <Section hasNoContainer className="mt-60">
                  <ContentBlock>
                    <ContentHeader
                      title="Page Banner"
                      subtitle="Upload an image for the page banner or select a solid color"
                      headerSize="md"
                      subtitleSize="md"
                      className="alt-font fw-600 secondary--clr pb-15"
                    />
                  </ContentBlock>

                  <ContentBlock cols={6}>
                    {
                      pageModel.bannerImageFilePath
                        ? (
                          <>
                            <CustomBlock className="flex-center pt-15 pb-30">
                              <Image
                                source={`${process.env.REACT_APP_API_URL}Attachments/${pageModel.bannerImageFilePath}`}
                                className="pl-10 pr-10"
                              />
                            </CustomBlock>

                            <FileUploadBlock onupdatefiles={setFile} labelIdle="Replace image" />

                            <CustomBlock className="flex-center">
                              <Button
                                text="Remove Banner Image"
                                className="danger--bg"
                                size="xs"
                                onClick={() => showConfirmModal(
                                  {
                                    title: 'Remove Banner Image',
                                    text: 'Are you sure you want to remove this banner Image?',
                                    rightBtnText: 'Confirm',
                                    btnAction: () => {
                                      deleteBannerImage();
                                    }
                                  }
                                )}
                              />
                            </CustomBlock>
                          </>
                        ) : (
                          <FileUploadBlock
                            labelIdle="Upload image here"
                            onupdatefiles={setFile}
                          />
                        )
                    }
                  </ContentBlock>

                  <ContentBlock cols={6}>
                    <InputBlock
                      label="Banner Color"
                      placeholder="Type color HEX Code e.g. #FFFFFF or select colors below"
                      iconRight={<FaSquare size={35} color={formik.values.bannerColor} />}
                      errorMessage={formik.errors.bannerColor}
                      inputState={`${helper.getInputClasses(formik, 'bannerColor')}`}
                      {...formik.getFieldProps('bannerColor')}
                    />

                    <CustomBlock className="ml-10 mr-10">
                      <SwatchesPicker
                        onChangeComplete={(c) => {
                          formik.setFieldValue('bannerColor', c.hex);
                        }}
                        className="w-100"
                      />
                    </CustomBlock>
                  </ContentBlock>
                </Section>
              </CustomBlock>

              {/* CONTENT */}
              <CustomBlock className="content-container--card-style--with-shadow mb-60">
                {pageId && (
                  <Section hasNoContainer className="">
                    <ContentBlock>
                      <ContentHeader
                        title="Page Content"
                        subtitle="Select from components to create a new content section or choose from available content sections"
                        headerSize="lg"
                        subtitleSize="md"
                        className="pb-15"
                        primaryButtonText="Add Content"
                        primaryButtonIconLeft={<MdOutlinePlaylistAdd className={iconColor} size={iconSize} />}
                        primaryButtonOnClick={() => setIsModalVisible(true)}
                        secondaryButtonText="Sort Items"
                        secondaryButtonOnClick={() => setIsSortModalOpen(true)}
                      />
                    </ContentBlock>

                    <ContentBlock>
                      <MUIDataTable
                        data={pageModel.contentSections}
                        columns={columns}
                        options={options}
                      />
                    </ContentBlock>
                  </Section>
                )}
              </CustomBlock>

              {/* SEARCH ENGINE OPTIMIZATION */}
              <CustomBlock className="content-container--card-style--with-shadow mb-60">
                <Section hasNoContainer>
                  <ContentBlock className="d-flex justify-content-between align-items-center flex-wrap-reverse">
                    <ContentHeader
                      title="Search Engine Optimization"
                      headerSize="lg"
                      subtitle="Enhance search rankings with SEO. Enter title tags, meta descriptions, and keywords."
                      subtitleSize="md"
                      // className="alt-font fw-600 secondary--clr pb-15"
                      className="pb-15"
                      containerClassName="pb-50"
                    />

                    <CustomBlock className="mb-10" style={{ height: '40px', margin: '0 5px', opacity: '0.90' }}>
                      <svg width="100%" height="100%" viewBox="0 0 268 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M103.14 75.79H148.44C148.573 75.79 148.7 75.8427 148.794 75.9364C148.887 76.0302 148.94 76.1574 148.94 76.29V90.46C148.94 90.5926 148.887 90.7198 148.794 90.8136C148.7 90.9073 148.573 90.96 148.44 90.96H86.3201C86.1875 90.96 86.0603 90.9073 85.9665 90.8136C85.8728 90.7198 85.8201 90.5926 85.8201 90.46V2.32C85.8201 2.18739 85.8728 2.06021 85.9665 1.96645C86.0603 1.87268 86.1875 1.82 86.3201 1.82H146.92C147.053 1.82 147.18 1.87268 147.274 1.96645C147.367 2.06021 147.42 2.18739 147.42 2.32V16.45C147.42 16.5826 147.367 16.7098 147.274 16.8036C147.18 16.8973 147.053 16.95 146.92 16.95H103.14C103.007 16.95 102.88 17.0027 102.787 17.0965C102.693 17.1902 102.64 17.3174 102.64 17.45V36.16C102.64 36.2926 102.693 36.4198 102.787 36.5135C102.88 36.6073 103.007 36.66 103.14 36.66H143.8C143.933 36.66 144.06 36.7127 144.154 36.8064C144.247 36.9002 144.3 37.0274 144.3 37.16V51.27C144.3 51.4026 144.247 51.5298 144.154 51.6236C144.06 51.7173 143.933 51.77 143.8 51.77H103.14C103.007 51.77 102.88 51.8227 102.787 51.9164C102.693 52.0102 102.64 52.1374 102.64 52.27V75.29C102.64 75.4226 102.693 75.5498 102.787 75.6436C102.88 75.7373 103.007 75.79 103.14 75.79Z" fill="#EA4235" />
                        <path d="M21.4501 20.13C18.6001 23.56 20.0701 28.27 23.6601 30.36C25.7734 31.58 28.1801 32.5567 30.8801 33.29C39.9134 35.7233 45.6934 37.3 48.2201 38.02C58.0701 40.83 67.0901 46.07 69.8101 56.7C72.1367 65.7733 70.4834 73.9 64.8501 81.08C57.0801 90.99 41.3201 92.59 29.3101 91.31C21.7367 90.5033 15.5901 88.1867 10.8701 84.36C3.97674 78.76 0.553413 70.8267 0.60008 60.56C0.606747 60.1933 0.790068 60.01 1.15007 60.01H16.3401C16.7267 60.01 16.9601 60.2033 17.0401 60.59C19.7101 74.16 29.2001 78.52 42.4401 75.95C47.0401 75.0633 50.2734 72.6633 52.1401 68.75C54.1501 64.53 52.9901 59.79 48.9001 57.37C46.9134 56.1967 44.8467 55.34 42.7001 54.8C36.4334 53.2333 30.3367 51.5933 24.4101 49.88C16.4601 47.58 9.57007 43.6 5.53007 35.96C0.280073 26.03 3.58007 13.86 12.2601 7.24001C20.5101 0.960005 33.1401 0.270003 43.5901 1.69C57.7901 3.61 67.1301 12.29 68.3401 27.26C68.3457 27.3118 68.3403 27.3643 68.3243 27.4139C68.3082 27.4635 68.2819 27.5092 68.2469 27.5479C68.212 27.5866 68.1693 27.6175 68.1216 27.6385C68.0738 27.6596 68.0222 27.6703 67.9701 27.67H51.2501C51.046 27.6699 50.8484 27.5965 50.6927 27.4628C50.537 27.329 50.4333 27.1437 50.4001 26.94C48.5401 15.51 36.3201 14.18 27.2301 16.7C24.7101 17.3933 22.7834 18.5367 21.4501 20.13Z" fill="#4385F5" />
                        <path d="M238.4 84.81L244.55 78.72C246.55 78.2133 248.56 78.95 250.58 80.93C255.753 85.9967 260.86 91.1367 265.9 96.35C266.893 97.3767 267.4 98.53 267.42 99.81C267.44 100.797 266.95 101.823 265.95 102.89C263.49 105.53 261.953 107.037 261.34 107.41C259.593 108.483 257.823 108.157 256.03 106.43C250.997 101.577 246.027 96.6433 241.12 91.63C239.44 89.91 238.453 88.5433 238.16 87.53C237.92 86.6767 238 85.77 238.4 84.81Z" fill="#4385F5" />
                        <path d="M233.29 14.84L222.66 26.24C215.667 19.2867 207.693 16.3367 198.74 17.39C186.647 18.81 178.52 25.4767 174.36 37.39C172.633 42.3367 172.477 48.0333 173.89 54.48C174.37 57.64 175.59 60.6233 177.55 63.43C181.83 69.77 187.38 73.8133 194.2 75.56C209.42 79.45 223.63 71.74 228.9 57.37C231.607 49.9767 231.243 42.0767 227.81 33.67L238.85 22.03C245.09 32.2233 247.117 43.29 244.93 55.23C243.843 61.17 241.64 66.4967 238.32 71.21C238.155 71.4444 238.078 71.73 238.104 72.0157C238.129 72.3014 238.256 72.5688 238.46 72.77L244.55 78.72L238.4 84.81L232.75 79.26C232.601 79.1115 232.4 79.0257 232.189 79.0202C231.977 79.0146 231.77 79.0896 231.61 79.23C222.917 86.8767 213.137 90.7733 202.27 90.92C188.82 91.1 177.53 86.55 168.33 76.76C148.29 55.42 154.01 20.87 179.09 6.84C184.823 3.64 191.14 1.77667 198.04 1.25C211.42 0.223334 223.17 4.75333 233.29 14.84Z" fill="#FABC05" />
                        <path d="M238.85 22.03L227.81 33.67L207.87 55.19C207.638 55.4403 207.321 55.5946 206.982 55.6224C206.643 55.6502 206.307 55.5494 206.04 55.34L194.63 46.39C194.459 46.2563 194.244 46.1914 194.028 46.2081C193.811 46.2247 193.609 46.3217 193.46 46.48L177.55 63.43C175.59 60.6233 174.37 57.64 173.89 54.48L192.93 34.41C192.977 34.3629 193.04 34.3348 193.106 34.3311C193.173 34.3274 193.238 34.3484 193.29 34.39L205.35 43.86C205.511 43.9849 205.713 44.045 205.915 44.0283C206.117 44.0116 206.305 43.9194 206.44 43.77L222.66 26.24L233.29 14.84L238.96 8.78999C239.031 8.71398 239.087 8.62465 239.123 8.52711C239.16 8.42958 239.177 8.32577 239.173 8.22168C239.169 8.11759 239.145 8.01526 239.102 7.92058C239.058 7.8259 238.997 7.74074 238.92 7.67L235.5 4.5C235.434 4.43844 235.386 4.35974 235.362 4.27262C235.337 4.1855 235.338 4.09335 235.362 4.00639C235.387 3.91942 235.436 3.84103 235.502 3.77989C235.569 3.71876 235.651 3.67726 235.74 3.66L252.25 0.540001C252.316 0.527013 252.385 0.529819 252.45 0.548195C252.515 0.566571 252.574 0.600004 252.624 0.645775C252.674 0.691546 252.712 0.748374 252.736 0.811653C252.759 0.874933 252.768 0.942884 252.76 1.01L250.92 17.69C250.911 17.7829 250.876 17.8711 250.819 17.9447C250.762 18.0184 250.685 18.0744 250.596 18.1067C250.508 18.1389 250.412 18.146 250.319 18.1271C250.226 18.1083 250.14 18.0643 250.07 18L246.49 14.68C246.09 14.3067 245.707 14.3267 245.34 14.74L238.85 22.03Z" fill="#34A853" />
                      </svg>
                    </CustomBlock>
                  </ContentBlock>

                  <ContentBlock>
                    <ContentBlock cols={6}>
                      <InputBlock
                        label="Title Tag"
                        placeholder="e.g. Our Services"
                        className="sm"
                        errorMessage={formik.errors.title}
                        inputState={`${helper.getInputClasses(formik, 'title')}`}
                        {...formik.getFieldProps('title')}
                      />
                    </ContentBlock>
                  </ContentBlock>

                  <ContentBlock cols={6}>
                    <TextAreaBlock
                      label="Keywords"
                      placeholder="e.g. construction services, home renovation, custom home construction"
                      className="sm"
                      errorMessage={formik.errors.keywords}
                      inputState={`${helper.getInputClasses(formik, 'keywords')}`}
                      {...formik.getFieldProps('keywords')}
                      labelColor="dark--clr"
                    />
                  </ContentBlock>

                  <ContentBlock cols={6}>
                    <TextAreaBlock
                      label="Meta Description"
                      placeholder="e.g. Fast and reliable construction services! Local resources and 24/7 support."
                      className="sm"
                      errorMessage={formik.errors.description}
                      inputState={`${helper.getInputClasses(formik, 'description')}`}
                      {...formik.getFieldProps('description')}
                      labelColor="dark--clr"
                    />
                  </ContentBlock>
                </Section>
              </CustomBlock>

              {/* PAGE ACTIONS */}
              <FixedActionsBar
                primaryActionText={pageId > 0 ? 'Save Changes' : 'Create Page'}
                primaryActionColor="primary--bg"
                primaryActionOnClick={formik.handleSubmit}
                secondaryActionText="Cancel"
                secondaryActionTo="/cms/management/pages"
                optionalActionText={pageId > 0 ? 'Return to Listing' : ''}
                optionalActionTo="/cms/management/pages"
              />
            </FormBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* ADD Content Section MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={isModalVisible}
        size="md"
        contentHeader="Add Content Section"
        primaryModalActionText="Add"
        primaryModalActionColor="primary--bg"
        primaryModalActionOnClick={addContentSection}
        secondaryModalActionText="Create New"
        secondaryModalActionColor="secondary--bg"
        secondaryModalActionOnClick={() => {
          if (selectedOption !== null) {
            history.push(`/cms/management/${(selectedOption.value === 'BANNER') ? 'banner' : 'content-section'}/pageId/${pageId}`);
          } else {
            showAlert({ text: 'Please select type of Component you want to create', state: 'warning' });
          }
        }}
        onHide={hideContentSectionModal}
      >
        <Section hasNoContainer>
          <ContentBlock>
            <SelectBlock
              label="Type Of Component"
              placeholder="Select type of Component"
              options={typeSelectionOptions}
              value={selectedOption}
              onChange={setSelectedOption}
            />
          </ContentBlock>

          <ContentBlock className="mt-10">
            <SelectBlock
              label="Component"
              inputState={!selectedOption ? 'disabled' : ''}
              value={selectedContentBannerTypeOption}
              placeholder="Select a Component"
              options={contentBannerTypeOption}
              onChange={setSelectedContentBannerTypeOption}
              className="border-0"
            />
          </ContentBlock>
        </Section>
      </ModalBlock>

      {/* SORT MODAL */}
      <ModalBlock
        hasCloseAction
        centered
        isVisible={isSortModalOpen}
        size="md"
        contentHeader="Sort Sections"
        primaryModalActionText="Sort"
        primaryModalActionOnClick={sortPageContents}
        onHide={() => {
          setIsSortModalOpen(false);
        }}
      >
        <Section hasNoContainer>
          {
            sortList.length > 0
              ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(droppableProvided) => (
                      <div
                        ref={droppableProvided.innerRef}
                      >
                        {sortList.map((item, index) => (
                          <Draggable
                            key={item.id} draggableId={`${index}`}
                            index={index}
                          >
                            {(draggableProvided) => (
                              <div
                                key={`index_${item.id}`}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                className="content-container--card-style light-grey--sbg pt-15 pb-15 pl-20 pr-20 mb-15"
                              >
                                <p className="main-font fw-500">{item.name}</p>
                              </div>

                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <CustomBlock className="content-container--card-style light-grey--sbg pt-15 pb-15 pl-20 pr-20 mb-15">
                  <p className="main-font fw-500">No Items Available</p>
                </CustomBlock>
              )
          }
        </Section>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alert.actions,
  ...confirmModalRedux.actions
})(PageManagementPage);