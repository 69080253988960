/* eslint-disable object-shorthand */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-awesome-reveal';
// COMPONENTS
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import ContentSection from './ContentSection';

const Banner = (props) => {
  const {
    bannerBackgroundColor,
    title,
    titleColor,
    description,
    descriptionColor,
    imageSource,
    titlePosition,
    isDynamicBanner,
    isTitleUppercase,
    offset,
    className,
    primaryActionColor
  } = props;
  const [bannerTextOffset, setBannerTextOffset] = useState(0);

  const textPositions = {
    left: 'left',
    center: 'center',
    right: 'right'
  };

  const calculateHeight = () => {
    let staticBannerHeight = 0;
    let headerNavigationContainerHeight = 0;
    let staticBannerTextHeight = 0;

    setTimeout(() => {
      const staticBanner = document.querySelector('.static-banner.dynamic');
      if (staticBanner) {
        staticBannerHeight = staticBanner.offsetHeight;
      }
    }, 0);

    const staticBannerText = document.querySelector('.static-banner .title');
    if (staticBannerText) {
      staticBannerTextHeight = staticBannerText.offsetHeight;
    }

    const headerNavigationContainer = document.querySelector('.header-navigation-container');
    if (headerNavigationContainer) {
      headerNavigationContainerHeight = headerNavigationContainer.offsetHeight;
    }
  
    const marginTop = Math.abs((staticBannerHeight - headerNavigationContainerHeight + (staticBannerTextHeight / 2)) / 2);
    setBannerTextOffset(marginTop);
  };

  useEffect(() => {
    calculateHeight();

    window.addEventListener('resize', calculateHeight);
    window.addEventListener('scroll', calculateHeight);

    return () => {
      window.removeEventListener('resize', calculateHeight);
      window.removeEventListener('scroll', calculateHeight);
    };
  }, []);

  return (
    <CustomBlock
      className={`static-banner ${isDynamicBanner ? 'dynamic' : ''} ${titlePosition && textPositions[titlePosition]} ${className && className}`}
      style={{
        position: 'relative',
        backgroundImage: `url(${imageSource})`,
        backgroundColor: bannerBackgroundColor,
        backgroundPosition: `center ${offset * 0.25}px`
      }}
    >
      <h2
        className="title size-xl white--clr fw-700 text-uppercase w-100 text-center user-select-none"
        style={{
          position: 'absolute',
          top: '50%',
          left: '0',
          zIndex: 1,
          transform: 'translateY(-50%)',
          marginTop: `${bannerTextOffset}px`,
          textShadow: '3px 3px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Fade
          delay={200} cascade
          damping={1e-1}
        >
          {title}
        </Fade>
      </h2>

      {/* <ContentSection
        isTitleUppercase={isTitleUppercase}
        {...props}
        isTextOnly
        hasRoundCorners
        title={title}
        description={description}
        imageSource={imageSource}
        titleColor={titleColor}
        descriptionColor={descriptionColor}
        primaryActionColor={primaryActionColor || 'white--sbg dark--clr'}
        secondaryActionColor="secondary--bg"
        className="mt-0 border-2 border-solid border-white--sbg"
      /> */}
    </CustomBlock>
  );
};

Banner.propTypes = {
  // MAIN PROPS
  imageSource: PropTypes.number.isRequired,
  titlePosition: PropTypes.string,
  bannerBackgroundColor: PropTypes.string,

  // TITLE PROPS
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,

  // DESCRIPTION PROPS
  description: PropTypes.string,
  descriptionColor: PropTypes.string,

  // BOOLEAN PROPS
  isDynamicBanner: PropTypes.bool,
  isTitleUppercase: PropTypes.bool,
};

Banner.defaultProps = {
  // MAIN PROPS
  description: '',
  titlePosition: 'center',
  bannerBackgroundColor: '#213c63',

  // TITLE PROPS
  titleColor: 'white--clr',

  // DESCRIPTION PROPS
  descriptionColor: 'white--clr',

  // BOOLEAN PROPS
  isDynamicBanner: false,
  isTitleUppercase: false,
};

export default Banner;