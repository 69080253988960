// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// ICONS
import { AiOutlineLoading } from 'react-icons/ai';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const TextAreaBlock = (props) => {
  const {
    containerClassName,
    className,
    backgroundColor,
    label,
    labelColor,
    inputLabel,
    placeholder,
    inputState,
    errorMessage,
    successMessage,
    warningMessage,
    isLabelLoading,
    isRequired,
    isDisabled,
    hasBorder,
  } = props;

  const errorMessageSuffix = 'is required';
  const inputStates = {
    error: 'error',
    warning: 'warning',
    success: 'success',
    disabled: 'disabled'
  };

  return (
    <CustomBlock className={`input-block-container ${containerClassName}`}>
      {
        inputLabel
        && (
          <span className={`static-floating-label ${inputState}`}>
            {inputLabel}
            {isRequired && <span className="danger--clr fw-600">*</span>}
            {
              isLabelLoading
              && (
                <AiOutlineLoading
                  tabIndex="-1"
                  className="loading-indicator rotating success--clr ml-5"
                  size={16}
                  style={{ marginTop: -5 }}
                />
              )
            }
          </span>
        )
      }
      {
        label
        && (
          <span className={`static-label ${labelColor}`}>
            {label}
            {isRequired && <span className="danger--clr fw-600">*</span>}
            {
              isLabelLoading
              && (
                <AiOutlineLoading
                  tabIndex="-1"
                  className="loading-indicator rotating success--clr ml-10"
                  size={18}
                />
              )
            }
          </span>
        )
      }
      <textarea
        {...props}
        className={`input-block textarea ${className} ${backgroundColor} ${inputState && inputStates[inputState]} ${hasBorder && 'bordered'} ${!inputLabel && 'padded'}`}
        placeholder={placeholder}
        disabled={!!(isDisabled || inputState === 'disabled')}
      />
      {
        inputState === 'error'
        && (
          <span className={`input-state-text error-message ${!inputLabel || label ? 'unpadded' : ''}`} style={{ marginTop: '-14px' }}>
            {errorMessage || (inputLabel || label ? (inputLabel || `${label} ${errorMessageSuffix}`) : 'Error message undefined')}
          </span>
        )
      }
      {
        inputState === 'warning'
        && (
          <span className={`input-state-text warning-message ${!inputLabel || label ? 'unpadded' : ''}`}>
            {warningMessage}
          </span>
        )
      }
      {
        inputState === 'success'
        && (
          <span className={`input-state-text success-message ${!inputLabel || label ? 'unpadded' : ''}`}>
            {successMessage}
          </span>
        )
      }
    </CustomBlock>
  );
};

TextAreaBlock.propTypes = {
  // CONTAINER PROPS
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  backgroundColor: PropTypes.string,

  // LABEL PROPS
  label: PropTypes.string,
  labelColor: PropTypes.string,
  inputLabel: PropTypes.string,
  placeholder: PropTypes.string,

  // STATE PROPS
  inputState: PropTypes.string,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  warningMessage: PropTypes.string,

  // BOOLEAN PROPS
  isLabelLoading: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasBorder: PropTypes.bool,
};

TextAreaBlock.defaultProps = {
  // CONTAINER PROPS
  containerClassName: '',
  className: '',
  backgroundColor: 'light--sbg',

  // LABEL PROPS
  label: '',
  labelColor: '',
  inputLabel: '',
  placeholder: '',

  // STATE PROPS
  inputState: '',
  errorMessage: '',
  successMessage: '',
  warningMessage: '',

  // BOOLEAN PROPS
  isLabelLoading: false,
  isRequired: false,
  isDisabled: false,
  hasBorder: false,
};

export default TextAreaBlock;